import React, { useEffect, useState } from "react"
import Logo from "../../assets/logo.png"
import { checkLogin } from "../../Actions/commonController"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import {
    Button,
    Box,
    CircularProgress,
    Typography,
    Link,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from "@mui/material"
import { useAuth } from "../../Context/authContext"
import funnyMeme from "../../assets/funny.png"

const initialValue = {
    username: "",
    password: ""
}

const Login = () => {
    const [formValue, setFormValue] = useState(initialValue)
    const [loading, setLoading] = useState(false)
    const [secretCounter, setSecretCounter] = useState(0)
    const [errMessage, setErrMessage] = useState("")
    const [secretModal, setSecretModal] = useState(false)
    const [email, setEmail] = useState("")
    const [open, setOpen] = useState(false)
    const { username, password } = formValue
    const { login, isLogin } = useAuth()
    const navigate = useNavigate()

    const handleChange = (e) => {
        let { name, value } = e.target
        setFormValue({ ...formValue, [name]: value })
    }

    const handleEmailChange = (e, field) => {
        let { value } = e.target
        if (field === 'email') {
            setEmail(value)
        } else {
            setFormValue({ ...formValue, [field]: value })
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') handleSubmit(e)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        let formData = formValue
        let APIData = {
            username: formData.username,
            password: formData.password
        }

        setLoading(true)

        try {
            let { status, data } = await checkLogin(APIData)

            if (status === "success") {
                await login(data.token, data.tier, data.username, data.eid)
                toast.success("Login Successful!")
                navigate("/dashboard")
            } else {
                if (secretCounter === 0) {
                    setErrMessage("Incorrect username or password.")
                    setSecretCounter(1)
                } else if (secretCounter === 1 && password === "incorrect") {
                    setErrMessage("Try again")
                    setSecretCounter(2)
                } else if (secretCounter === 2 && password === "again") {
                    setErrMessage("Try again once more")
                    setSecretCounter(3)
                } else if (secretCounter === 3 && password === "again once more") {
                    setSecretModal(true)
                    setSecretCounter(0)
                    toast.error("Wut da hell bero??")
                } else {
                    toast.error("Login Unsuccessful!")
                }
            }
        } catch (error) {
            console.error("Error during login:", error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (isLogin) navigate("/dashboard")
    }, [isLogin])

    const handleResetPassword = (e) => {
        e.preventDefault()

        setOpen(false)
    }

    return (
        <div className="cover">
            <img src={Logo} />
            <h3>LOGIN</h3>
            <p>Login to access your account</p>

            <div className="login_input">
                <input type="text" name="username" value={username} onChange={handleChange} onKeyDown={handleKeyPress} required />
                <label for="username">Username</label>
            </div>

            <div className="login_input">
                <input type="password" name="password" value={password} onChange={handleChange} onKeyDown={handleKeyPress} required />
                <label for="password">Password</label>
                <div className="errMessage">{errMessage}</div>
            </div>

            <Button variant="contained" onClick={handleSubmit}>
                {loading ? (
                    <Box sx={{ display: "flex" }}>
                        <CircularProgress size={20} sx={{ color: "white" }} />
                    </Box>
                ) : (
                    "Login"
                )}
            </Button>

            {/* Forgot Password */}
            {/* <Typography variant="body2" mt={2} style={{ textAlign: "center", fontSize: "16px" }}>
                <Link href="#" onClick={() => setOpen(true)}> Forgot Password?</Link>
            </Typography> */}

            {/* Reset Password Dialog */}
            <Dialog open={open} onClose={() => setOpen(false)} PaperProps={{ style: { width: "400px", height: "300px" } }}>
                <DialogTitle className="dialogTitle">Reset Password</DialogTitle>
                <DialogContent className="dialogContent" style={{ display: "flex", flexDirection: "column" }}>
                    <p variant="body1" style={{ marginBottom: "20px", color: "#777777" }}>Enter your email to reset your password</p>
                    <div className="login_input">
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => handleEmailChange(e, 'email')}
                            style={{ width: "100%", marginBottom: "20px", padding: "10px", fontSize: "16px" }}
                            required
                        />
                        {/* <label for="email">Email</label> */}
                    </div>
                    <Typography variant="body2" style={{ textAlign: "center", fontSize: "14px", margin: 0 }}>
                        <Link component="button" className="dialogLink" onClick={() => setOpen(false)}>Remember your password? Go Back</Link>
                    </Typography>
                </DialogContent>
                <DialogActions style={{ padding: "20px", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: 0 }}>
                    <Button onClick={handleResetPassword} variant="contained" className="customButton">
                        Reset
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={secretModal} onClose={() => { setSecretModal(false) }}>
                <img src={funnyMeme} />
            </Dialog>

        </div>
    )
}

export default Login