import React, { useState, useEffect } from "react"
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Button,
  CircularProgress,
  Modal,
  Typography,
  Box,
  Stack,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Step,
  Stepper,
  StepLabel,
} from "@mui/material"
import PaginationTable from "../../Pagination"
import {
  cancelSalaryCertificateRequest,
  fetchEmpSalaryCertificates
} from "../../../Actions/commonController"
import { useAuth } from "../../../Context/authContext"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty"
import CancelIcon from "@mui/icons-material/Cancel"
import { FormatDate } from "../../Utilities/DateFormatter"
import { toast } from "react-toastify"

export default function MySalaryCertificatesTable() {
  const [page, setPage] = useState(1)
  const [quantity, setQuantity] = useState(10)
  const [totalItems, setTotalItems] = useState(0)
  const [data, setData] = useState([])
  const [selectedReason, setSelectedReason] = useState("")
  const [reasonModalOpen, setReasonModalOpen] = useState(false)
  const [trackerModalOpen, setTrackerModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loader, setLoader] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedRequestId, setSelectedRequestId] = useState(null)
  const [statusFilter, setStatusFilter] = useState("all")
  const { eid } = useAuth()
  const [rejectModalOpen, setRejectModalOpen] = useState(false)
  const [selectedRejectInfo, setSelectedRejectInfo] = useState({
    rejectedByName: "",
    rejectedreason: "",
  })
  const [trackerData, setTrackerData] = useState({
    pending: [],
    accepted: [],
    rejected: [],
  })

  const fetchRequests = async () => {
    try {
      const APIData = {
        empId: eid,
        quantity: quantity,
        page: page,
        status: statusFilter,
      }
      setLoader(true)
      const { data, status } = await fetchEmpSalaryCertificates(APIData)
      if (status === "success") {
        setData(data)
        setTotalItems(data.length)
      } else {
        console.error("Error fetching salary certificates", status)
      }
    } catch (error) {
      console.error("Error in fetchRequests function", error)
    }
    setReasonModalOpen(false)
    setLoader(false)
  }

  const handleConfirmDialog = async () => {
    setLoading(true)
    try {
      let APIData = {
        salaryReqId: selectedRequestId,
      }
      const { status } = await cancelSalaryCertificateRequest(APIData)
      if (status === "success") {
        fetchRequests()
        toast.success("Salary Certificate request cancelled!")
      } else {
        console.error("Error cancelling request", status)
      }
    } catch (error) {
      console.error("Error cancelling request", error)
    }
    setLoading(false)
    setDialogOpen(false)
    setSelectedRequestId(null)
  }

  const handleFilter = (status) => {
    setStatusFilter(status)
    setPage(1)
  }

  const handlePageClick = async (newPage) => {
    setPage(newPage)
  }

  const handleItemsPerPageChange = async (newRowsPerPage) => {
    setQuantity(newRowsPerPage)
    setPage(1)
  }

  const handleRejectedInfo = (rejectedByName, rejectedreason) => {
    setSelectedRejectInfo({ rejectedByName, rejectedreason })
    setRejectModalOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
    setSelectedRequestId(null)
  }

  const handleCloseModal = () => {
    setReasonModalOpen(false)
    setTrackerModalOpen(false)
    setRejectModalOpen(false)
  }

  const handleCancelClick = (requestId) => {
    setSelectedRequestId(requestId)
    setDialogOpen(true)
  }

  const handleReasonButton = (reason) => {
    setSelectedReason(reason)
    setReasonModalOpen(true)
  }

  useEffect(() => {
    fetchRequests()
  }, [eid, statusFilter, page, quantity])

  const chipColor = (status) => {
    switch (status) {
      case "Pending":
        return "primary"
      case "Cancelled":
        return "warning"
      case "Rejected":
        return "error"
      case "Accepted":
        return "success"
      default:
        return "default"
    }
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }

  const handleTrackerButtonClick = (item) => {
    const tracker = {
      pending: item.pendingNames ? item.pendingNames.split(",") : [],
      accepted: item.acceptedNames ? item.acceptedNames.split(",") : [],
      rejected: item.rejectedNames ? item.rejectedNames.split(",") : [],
    }
    setTrackerData(tracker)
    setTrackerModalOpen(true)
  }

  const renderTracker = () => {
    const steps = [
      ...trackerData.accepted.map((name) => ({ name, status: "accepted" })),
      ...trackerData.pending.map((name) => ({ name, status: "pending" })),
      ...trackerData.rejected.map((name) => ({ name, status: "rejected" })),
    ]

    return (
      <Box sx={{ width: "100%", marginTop: 2 }}>
        <Stepper alternativeLabel>
          {steps.map((step, index) => (
            <Step key={index} active>
              <StepLabel
                StepIconComponent={() =>
                  step.status === "accepted" ? (
                    <CheckCircleIcon color="success" />
                  ) : step.status === "rejected" ? (
                    <CancelIcon color="error" />
                  ) : (
                    <HourglassEmptyIcon color="warning" />
                  )
                }
              >
                {step.name}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    )
  }

  return (
    <Paper className="pagePaper">
      <span className="info">
        <h5>My Salary Certificate Requests</h5>
      </span>
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "30px" }}>
        <Button
          variant="contained"
          onClick={() => handleFilter("all")}
          sx={{
            backgroundColor: statusFilter === "all" ? "#2596BE" : "transparent",
            color: statusFilter === "all" ? "white" : "#2596BE",
            marginRight: "10px",
            "&:hover": {
              backgroundColor: "#2596BE",
              color: "white",
            },
          }}
        >
          All
        </Button>

        <Button
          variant="contained"
          onClick={() => handleFilter("Pending")}
          sx={{
            backgroundColor: statusFilter === "pending" ? "#2596BE" : "transparent",
            color: statusFilter === "pending" ? "white" : "#2596BE",
            marginRight: "10px",
            "&:hover": {
              backgroundColor: "#2596BE",
              color: "white",
            },
          }}
        >
          Pending
        </Button>
        <Button
          variant="contained"
          onClick={() => handleFilter("Accepted")}
          sx={{
            backgroundColor: statusFilter === "accepted" ? "#2596BE" : "transparent",
            color: statusFilter === "accepted" ? "white" : "#2596BE",
            marginRight: "10px",
            "&:hover": {
              backgroundColor: "#2596BE",
              color: "white",
            },
          }}
        >
          Accepted
        </Button>
        <Button
          variant="contained"
          onClick={() => handleFilter("Rejected")}
          sx={{
            backgroundColor: statusFilter === "rejected" ? "#2596BE" : "transparent",
            color: statusFilter === "rejected" ? "white" : "#2596BE",
            marginRight: "10px",
            "&:hover": {
              backgroundColor: "#2596BE",
              color: "white",
            },
          }}
        >
          Rejected
        </Button>
        <Button
          variant="contained"
          onClick={() => handleFilter("Cancelled")}
          sx={{
            backgroundColor: statusFilter === "cancelled" ? "#2596BE" : "transparent",
            color: statusFilter === "cancelled" ? "white" : "#2596BE",
            "&:hover": {
              backgroundColor: "#2596BE",
              color: "white",
            },
          }}
        >
          Cancelled
        </Button>
      </div>

      <div className="row" style={{
        paddingTop: "60px",
      }}>
        <div className="col-md-12">
          <TableContainer
            sx={{
              overflowY: "auto",
              maxHeight: "500px"
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" className="tableHeader">SR NO.</TableCell>
                  <TableCell align="center" className="tableHeader">REQUEST DATE</TableCell>
                  <TableCell align="center" className="tableHeader">TO WHOM</TableCell>
                  <TableCell align="center" className="tableHeader">COUNTRY</TableCell>
                  <TableCell align="center" className="tableHeader">STATUS</TableCell>
                  <TableCell align="center" className="tableHeader">REASON</TableCell>
                  <TableCell align="center" className="tableHeader">ACTIONS</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {loader ? (
                  <TableRow>
                    <TableCell colSpan={11} style={{ textAlign: "center" }}>
                      <CircularProgress size={50} />
                    </TableCell>
                  </TableRow>
                ) : data && data.length > 0 ? (
                  data.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell align="center" className="p-0">
                        {index === data.length - 1 ? 1 : data.length - index}
                      </TableCell>
                      <TableCell align="center" className="p-0">{FormatDate(item.createdat)}</TableCell>
                      <TableCell align="center" className="p-0">{item.towhom}</TableCell>
                      <TableCell align="center" className="p-0">{item.country}</TableCell>
                      <TableCell align="center" className="p-0">
                        <Stack direction="row" spacing={1} justifyContent="center">
                          <Chip label={item.status} color={chipColor(item.status)} />
                        </Stack>
                      </TableCell>
                      <TableCell align="center" className="p-0">
                        <Button variant="contained" className="tableButton" onClick={() => handleReasonButton(item.reason)}>
                          View
                        </Button>
                      </TableCell>
                      <TableCell align="center" className="p-0">
                        {item.status === "Pending" ? (
                          <>
                            <Button variant="contained" className="btn cancel" onClick={() => handleCancelClick(item.id)}>
                              Cancel
                            </Button>
                            <Button variant="contained" className="btn tracker" onClick={() => handleTrackerButtonClick(item)}>
                              Tracker
                            </Button>
                          </>
                        ) : item.status === "Rejected" ? (
                          <Button variant="contained" className="btn rjct" onClick={() => handleRejectedInfo(item.rejectedByName, item.rejectedreason)}>
                            Info
                          </Button>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={11} style={{ textAlign: "center" }}>No data found</TableCell>
                  </TableRow>
                )}
              </TableBody>

            </Table>
          </TableContainer>
        </div>
      </div>

      {/* Cancel Modal*/}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Cancel Salary Certificate Request</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to cancel this request?
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <Button
            style={{
              backgroundColor: "#FF0000",
              color: "white",
              width: "60px",
              height: "35px",
            }}
            onClick={handleCloseDialog}
            autoFocus
            disabled={loading}
          >
            No
          </Button>
          <Button
            sx={{
              backgroundColor: 'primary.main',
              color: 'white',
              '&:hover': {
                backgroundColor: 'primary.dark',
              },
              width: '60px',
              height: '35px',
            }}
            onClick={handleConfirmDialog}
            disabled={loading}
            autoFocus
          >
            {loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : "Yes"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Reason Modal */}
      <Modal
        open={reasonModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="reason-modal-title"
        aria-describedby="reason-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "none",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="reason-modal-title" variant="h6" component="h2">
            Reason
          </Typography>
          <Typography id="reason-modal-description" sx={{ mt: 2 }}>
            {selectedReason}
          </Typography>
        </Box>
      </Modal>

      {/* Rejection Info Modal */}
      <Modal
        open={rejectModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="rejection-modal-title"
        aria-describedby="rejection-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "none",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="rejection-modal-title" variant="h6" component="h2">
            Reason for Rejection
          </Typography>
          <Typography id="rejection-modal-description" sx={{ mt: 2 }}>
            {selectedRejectInfo.rejectedreason}
          </Typography>
          <Typography sx={{ mt: 2, fontStyle: "italic" }}>
            Rejected by: {selectedRejectInfo.rejectedByName}
          </Typography>
        </Box>
      </Modal>

      {/* Tracker Modal */}
      <Modal
        open={trackerModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="tracker-modal-title"
        aria-describedby="tracker-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "none",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="tracker-modal-title" variant="h6" component="h2">
            Approval Tracker
          </Typography>
          {renderTracker()}
        </Box>
      </Modal>

      <PaginationTable
        totalItems={totalItems}
        itemsPerPage={quantity}
        onPageChange={handlePageClick}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </Paper>
  )
}