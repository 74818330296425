import React, { useState } from "react"
import { useDropzone } from "react-dropzone"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import DeleteIcon from "@mui/icons-material/Delete"
import IconButton from "@mui/material/IconButton"

const isValidFileType = (file) => {
  const allowedFileTypes = ["image/jpeg", "image/png", "image/gif"]
  return allowedFileTypes.includes(file.type)
}

function UploadedFiles({ uploadedFiles, handleRemoveFile }) {
  return (
    <div className="uploaded-files-container">
      {uploadedFiles.map((file, index) => (
        <div className="uploaded-file" key={index}>
          <span className="file-name">{file.name}</span>
          <IconButton className="delete-button" onClick={() => handleRemoveFile(index)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ))}
    </div>
  )
}

export default function DragNDrop({ onDrop, setUploadedFiles, uploadedFiles, handleRemoveFile }) {
  const [uploadIconClicked, setUploadIconClicked] = useState(false)

  const handleDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      if (isValidFileType(file)) {
      } else {
        console.warn(`Skipping invalid file: ${file.name}`)
      }
    })

    setUploadedFiles((prevFiles) => [...prevFiles, ...acceptedFiles])
    onDrop(acceptedFiles)
    setUploadIconClicked(false)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setUploadedFiles((prevFiles) =>
        uploadIconClicked ? prevFiles : [...prevFiles, ...acceptedFiles]
      )
      handleDrop(acceptedFiles)
      setUploadIconClicked(false)
    },
    accept: "image/*",
  })

  return (
    <div>
      <div className="dropzone-container" {...getRootProps()} style={{ cursor: "pointer" }}>
        <input {...getInputProps()} />
        <CloudUploadIcon
          className={`dropzone-icon ${isDragActive ? "active" : ""}`}
          onClick={() => setUploadIconClicked(true)}
        />
        <p className="dropzone-text">
          {isDragActive
            ? "Drop the files here"
            : 'Drag "n" drop some files here, or click to select files'}
        </p>
      </div>

      <div className="drag-drop">
        {uploadedFiles.length > 0 && (
          <UploadedFiles uploadedFiles={uploadedFiles} handleRemoveFile={handleRemoveFile} />
        )}
      </div>
    </div>
  )
}