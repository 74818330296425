import React, { useState } from "react"
import {
  Grid,
  Paper,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  styled,
  CircularProgress,
} from "@mui/material"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { applySalaryCert } from "../Actions/commonController"
import { useAuth } from "../Context/authContext"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  borderRadius: "5px",
  fontFamily: "Open Sans",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
}))

const RowContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))

const Divider = styled("hr")({
  height: "4px",
  borderTop: "1px solid #ccc",
  background: "linear-gradient(to right, #00cc00, #00ff00)",
})

export default function DashboardEmployees() {

  const [salaryCertOpen, setSalaryCertOpen] = useState(false)
  const [reason, setReason] = useState("")
  const [loading, setLoading] = useState(false)
  const { eid } = useAuth()
  const navigate = useNavigate()

  const handleClickOpen = () => {
    setReason("")
    setSalaryCertOpen(true)
  }

  const handleClose = () => {
    setReason("")
    setSalaryCertOpen(false)
  }

  const handleLeaveFormOpen = () => {
    navigate("/leave/leave-request-form")
  }

  const handleConfirm = async () => {
    try {
      let APIData = {
        empid: eid,
        reason: reason,
      }
      setLoading(true)
      const { status } = await applySalaryCert(APIData)
      if (status === "success") {
        toast.success("Salary certificate request sent successfully!")
      } else {
        toast.error("Failed to send salary certificate request")
      }
    } catch (error) {
      toast.error("Error requesting salary certificate")
      console.error("Error:", error)
    }
    setLoading(false)
    handleClose()
  }

  return (
    <Box>
      {/* row 1 */}
      <RowContainer>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Item sx={{ padding: "25px 15px" }}>
              <div className="cards">
                <h2 className="p-0">TOTAL NO. OF EMPLOYEES</h2>
                <h4>1000</h4>
                <div className="dividerParent">
                  <Divider />
                </div>
                <div className="percentage">
                  <div className="box">
                    <ArrowDropUpIcon /> 4.6%
                  </div>
                  <p>Today</p>
                </div>
              </div>
            </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Item sx={{ padding: "25px 15px" }}>
              <div className="cards">
                <h2 className="p-0">MONTHLY NEW HIRES</h2>
                <h4>20</h4>
                <div className="dividerParent">
                  <Divider />
                </div>
                <div className="percentage">
                  <div className="box">
                    <ArrowDropDownIcon /> 10%
                  </div>
                  <p>Month</p>
                </div>
              </div>
            </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Item sx={{ padding: "25px 15px" }}>
              <div className="cards">
                <h2 className="p-0">DEPARTMENTS</h2>
                <h4>5</h4>
                <div className="dividerParent">
                  <Divider />
                </div>
                <div className="percentage">
                  <div className="box">
                    <ArrowDropUpIcon /> 2.3%
                  </div>
                  <p>Today</p>
                </div>
              </div>
            </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Item sx={{ padding: "25px 15px" }}>
              <div className="cards">
                <h2 className="p-0">NO. OF OPEN POSITIONS</h2>
                <h4>6</h4>
                <div className="dividerParent">
                  <Divider />
                </div>
                <div className="percentage">
                  <div className="box">
                    <ArrowDropDownIcon /> 2.3%
                  </div>
                  <p>Today</p>
                </div>
              </div>
            </Item>
          </Grid>
        </Grid>
      </RowContainer>

      {/* row 2 */}
      <RowContainer>
        <Grid container spacing={2}>
          <Grid item xs={12} md={9}>
            <Item sx={{ padding: "25px 15px" }}>
              <div className="cards">
                <h2 className="p-0">LEAVE REQUEST UPDATES</h2>
              </div>
            </Item>
          </Grid>
          <Grid item xs={12} md={3}>
            <Item sx={{ padding: "25px 15px" }}>
              <div className="cards">
                <h2 className="p-0">QUICK LINKS</h2>
                <span style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginTop: "20px",
                      width: "250px",
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      backgroundColor: "#2596BE"
                    }}
                    onClick={handleLeaveFormOpen}
                  >
                    Request Leave Application
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginTop: "20px",
                      width: "250px",
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      backgroundColor: "#2596BE"
                    }}
                    onClick={handleClickOpen}
                  >
                    Request Salary Certificate
                  </Button>
                </span>
              </div>
            </Item>
          </Grid>
        </Grid>
      </RowContainer>

      {/* Request Salary Certificate Button */}
      <Dialog open={salaryCertOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Request Salary Certificate</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the reason for requesting the salary certificate.
          </DialogContentText>
          <TextField
            sx={{ marginTop: "25px" }}
            margin="dense"
            id="reason"
            label="Reason"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-between", padding: "20px" }}>
          <Button
            sx={{ backgroundColor: "red", color: "white", "&:hover": { backgroundColor: "darkred" }, width: "80px", height: "35px" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            sx={{ backgroundColor: "#2596BE", color: "white", "&:hover": { backgroundColor: "#1c7da0" }, width: "80px", height: "35px" }}
            onClick={handleConfirm}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>

    </Box >
  )
}
