import React, { useEffect, useState } from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import "./App.css"

import Login from "./Pages/Login/Login"
import Dashboard from "./Pages/Dashboard/Dashboard"
import Attendance from "./Pages/Attendance/Attendance"

// Employee Imports
import NewEmployee from "./Pages/Employee/newEmployee"
import ExistingEmployee from "./Pages/Employee/existingEmployee"
import InactiveEmployee from "./Pages/Employee/inactiveEmployee"
import AddNewEmployee from "./Pages/Employee/addNewEmployee"
import EditEmployeeInformation from "./Pages/Employee/editEmployeeInformation"
import TrackHistory from "./Pages/Employee/trackHistory"
import ManageEmployee from "./Pages/Employee/ManageEmployee"

//Salary Certificates
import SalaryCertificateRequests from "./Pages/Employee/salaryCertificateRequests"
import MySalaryCertificates from "./Pages/Employee/MySalaryCertificates"

//Leave Imports
import LeaveApplications from "./Pages/Leave/LeaveApplications"
import PendingLeaves from "./Pages/Leave/PendingLeaves"

// Recruitment Imports
import Recruitment from "./Pages/Recruitment/Recruitment"
import JobOpenings from "./Pages/Recruitment/jobOpening"
import ApplicationsReceived from "./Pages/Recruitment/applicationsRecieved"
import InterviewScheduled from "./Pages/Recruitment/interviewScheduled"
import CandidateDatabase from "./Pages/Recruitment/candidateDatabase"

// Payroll Imports
import Payroll from "./Pages/Payroll/Payroll"
import MonthlyPayroll from "./Pages/Payroll/monthlyPayroll"
import PaySlipsTable from "./Components/Tables/PayrollTables/PaySlipsTable"
import AllowanceAndDeductions from "./Pages/Payroll/allowanceAndDeductions"

// Report Imports
import Reports from "./Pages/Reports/Reports"
import EmployeeReports from "./Pages/Reports/employeeReports"
import AttendanceReports from "./Pages/Reports/attendanceReports"
import PayrollReports from "./Pages/Reports/payrollReports"
import RecruitmentReports from "./Pages/Reports/recruitmentReports"

//Leave Request Form
import LeaveRequest from "./Pages/Leave/LeaveRequest"

// Setting Imports
import UserProfile from "./Pages/Settings/userProfile"
import ChangePassword from "./Pages/Settings/ChangePassword"
import Error404 from "./Pages/Error/Error404"

// API Imports
import { useAuth } from "./Context/authContext"
import { checkAuth } from "./Actions/commonController"
import { SetAuthorizationHeader, removeAuthorizationHeader } from "./Config/axiosInterceptor"
import Layout from "./Layout/layout"

const App = () => {
  const { login, logout, isLogin, tier } = useAuth()
  const [loader, setLoader] = useState(false)

  const startup = async (dt) => {
    await SetAuthorizationHeader(dt)
    let { tier, eid, username, status } = await checkAuth(dt)
    if (status === "success") {
      await login(dt, tier, username, eid)
    } else {
      await removeAuthorizationHeader()
      await logout()
    }
    setLoader(true)
  }

  useEffect(() => {
    let dt = localStorage.getItem("ttk")
    if (dt) startup(dt)
    else setLoader(true)
  }, [])

  return (
    <Router>
      {
        loader &&
        <Routes>
          {
            tier != 4 &&
            <>
              <Route path="/employee/add-new-employee" element={isLogin ? <Layout component={<AddNewEmployee />} /> : <Error404 />} />
              <Route path="/employee/edit-employee/:empid" element={isLogin ? <Layout component={<EditEmployeeInformation />} /> : <Error404 />} />
              <Route path="/employee/track-history/:empid" element={isLogin ? <Layout component={<TrackHistory />} /> : <Error404 />} />
              <Route path="/employee/new-employee-list" element={isLogin ? <Layout component={<NewEmployee />} /> : <Error404 />} />
              <Route path="/employee/existing-employee-list" element={isLogin ? <Layout component={<ExistingEmployee />} /> : <Error404 />} />
              <Route path="/employee/inactive-employee-list" element={isLogin ? <Layout component={<InactiveEmployee />} /> : <Error404 />} />
              <Route path="/employee/manage-employees" element={isLogin ? <Layout component={<ManageEmployee />} /> : <Error404 />} />
              <Route path="/leave/leave-applications-list" element={isLogin ? <Layout component={<LeaveApplications />} /> : <Error404 />} />
              <Route path="/salary-cert/salary-certificate-requests" element={isLogin ? <Layout component={<SalaryCertificateRequests />} /> : <Error404 />} />
              <Route path="/salary-cert/my-salary-certificate-requests" element={isLogin ? <Layout component={<MySalaryCertificates />} /> : <Error404 />} />
              <Route path="/recruitment" element={isLogin ? <Layout component={<Recruitment />} /> : <Error404 />} />
              <Route path="/recruitment/openings" element={isLogin ? <Layout component={<JobOpenings />} /> : <Error404 />} />
              <Route path="/recruitment/applications" element={isLogin ? <Layout component={<ApplicationsReceived />} /> : <Error404 />} />
              <Route path="/recruitment/interviews" element={isLogin ? <Layout component={<InterviewScheduled />} /> : <Error404 />} />
              <Route path="/recruitment/candidates" element={isLogin ? <Layout component={<CandidateDatabase />} /> : <Error404 />} />
              <Route path="/payroll" element={isLogin ? <Layout component={<Payroll />} /> : <Error404 />} />
              <Route path="/payroll/monthly" element={isLogin ? <Layout component={<MonthlyPayroll />} /> : <Error404 />} />
              <Route path="/payroll/pay-slips" element={isLogin ? <Layout component={<PaySlipsTable />} /> : <Error404 />} />
              <Route path="/payroll/allowance-deductions" element={isLogin ? <Layout component={<AllowanceAndDeductions />} /> : <Error404 />} />
              <Route path="/reports" element={isLogin ? <Layout component={<Reports />} /> : <Error404 />} />
              <Route path="/reports/employee-reports" element={isLogin ? <Layout component={<EmployeeReports />} /> : <Error404 />} />
              <Route path="/reports/attendance" element={isLogin ? <Layout component={<AttendanceReports />} /> : <Error404 />} />
              <Route path="/reports/payroll-reports" element={isLogin ? <Layout component={<PayrollReports />} /> : <Error404 />} />
              <Route path="/reports/recruitment" element={isLogin ? <Layout component={<RecruitmentReports />} /> : <Error404 />} />
              <Route path="/profile" element={isLogin ? <Layout component={<UserProfile />} /> : <Error404 />} />
              <Route path="/change_password" element={isLogin ? <Layout component={<ChangePassword />} /> : <Error404 />} />

            </>
          }

          <Route path="/dashboard" element={isLogin ? <Layout component={<Dashboard />} /> : <Error404 />} />
          <Route path="/attendance" element={isLogin ? <Layout component={<Attendance />} /> : <Error404 />} />
          <Route path="/salary-cert/my-salary-certificate-requests" element={isLogin ? <Layout component={<MySalaryCertificates />} /> : <Error404 />} />
          <Route path="/profile" element={isLogin ? <Layout component={<UserProfile />} /> : <Error404 />} />
          <Route path="/leave/pending-leaves-list" element={isLogin ? <Layout component={<PendingLeaves />} /> : <Error404 />} />
          <Route path="/leave/leave-request-form/:empid?" element={isLogin ? <Layout component={<LeaveRequest />} /> : <Error404 />} />
          <Route path="/change_password" element={isLogin ? <Layout component={<ChangePassword />} /> : <Error404 />} />

          {/* Error Paths*/}
          <Route path="/*" element={<Error404 />} />
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      }


    </Router>
  )
}

export default App