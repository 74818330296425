import React, { useEffect, useState } from "react"
import {
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    CircularProgress,
    TableBody,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
} from "@mui/material"
import {
    fetchActiveEmpCount,
    fetchActiveEmpRecords,
    // markEmpAsAbsconded
} from "../../../Actions/commonController"
import SearchBar from "../../SearchBar"
import PaginationTable from "../../Pagination"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../../Context/authContext"
import { toast } from "react-toastify"

import PersonalDetailsModal from "../../Modals/PersonalDetailsModal"
import EmployeeDetailsModal from "../../Modals/EmployeeDetailsModal"
import JoiningDetailsModal from "../../Modals/JoiningDetailsModal"
import VisaDetailsModal from "../../Modals/VisaDetailsModal"
import ContactDetailsModal from "../../Modals/ContactDetailsModal"
import SalaryDetailsModal from "../../Modals/SalaryDetailsModal"
import ContractDetailsModal from "../../Modals/ContractDetailsModal"
import DocumentDetailsModal from "../../Modals/DocumentDetailsModal"
import TerminateModal from "../../Modals/TerminateModal"

const columns = [
    { id: "Id", label: "EMPID", minWidth: 40 },
    { id: "name", label: "NAME", minWidth: 100 },
    { id: "personalDetails", label: "PERSONAL DETAILS", minWidth: 80 },
    { id: "employeeDetails", label: "EMPLOYEE DETAILS", minWidth: 80 },
    { id: "joiningDetails", label: "JOINING DETAILS", minWidth: 80 },
    { id: "visaDetails", label: "VISA DETAILS", minWidth: 80 },
    { id: "contactDetails", label: "CONTACT DETAILS", minWidth: 80 },
    { id: "salaryDetails", label: "SALARY DETAILS", minWidth: 80 },
    { id: "contractDetails", label: "CONTRACT DETAILS", minWidth: 80 },
    { id: "documentDetails", label: "DOCUMENT DETAILS", minWidth: 80 },
    { id: "actions", label: "ACTIONS", minWidth: 80 },
]

const actionButtonStyle = {
    margin: "4px",
    fontSize: "10px",
}

export default function ExistingEmployeeTable() {
    const [page, setPage] = useState(1)
    const [quantity, setQuantity] = useState(10)
    const [totalItems, setTotalItems] = useState(0)
    const [selectedData, setSelectedData] = useState(null)
    const [data, setData] = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const [detailsModalOpen, setDetailsModalOpen] = useState("")
    const [loader, setLoader] = useState(false)
    const [initialLoad, setInitialLoad] = useState(false)
    const [terminateModalOpen, setTerminateModalOpen] = useState(false)
    const [terminateEmpId, setTerminateEmpId] = useState(null)
    const [statusFilter, setStatusFilter] = useState('All')
    // const [abscondModalOpen, setAbscondModalOpen] = useState(false)
    // const [abscondEmpId, setAbscondEmpId] = useState(null)
    // const [loading, setLoading] = useState(false)

    const { logout } = useAuth()

    const navigation = useNavigate()

    const handleSearch = async (searchData, type) => {
        setSearchTerm(searchData)
    }

    const handleFilter = (status) => {
        setStatusFilter(status)
    }

    const initialization = async () => {
        try {
            let APIData = {
                search: searchTerm,
                status: statusFilter,
            }
            setLoader(true)
            let { status, data } = await fetchActiveEmpCount(APIData, logout)

            if (status === "success") {
                setTotalItems(data)
                loadData()
            }
        } catch (err) {
            console.error("Error in loadData function", err)
        }
    }

    const loadData = async () => {
        try {
            let APIData = {
                quantity: quantity,
                page: page,
                search: searchTerm,
                status: statusFilter,
            }

            setLoader(true)
            let { status, data } = await fetchActiveEmpRecords(APIData, logout)
            if (status === "success") {
                const updatedData = data.map(item => ({
                    ...item,
                    status: item.absconded ? "Absconded" : "Active"
                }))
                setData(updatedData)
            }
            else console.error("Error fetching employee records:", status)
        } catch (error) {
            console.error("Error in loadData function:", error)
        }
        setLoader(false)
    }

    // const handleMarkAsAbsconded = async () => {
    //     try {
    //         let APIData = {
    //             empid: abscondEmpId,
    //         }
    //         setLoading(true)
    //         let { status } = await markEmpAsAbsconded(APIData)
    //         if (status === "success") {
    //             toast.success("Employee marked as Absconded.")
    //             setAbscondModalOpen(false)
    //             setAbscondEmpId(null)
    //             setPage(1)
    //         }
    //     } catch (error) {
    //         console.error("Error marking employee as absconded:", error.message)
    //         toast.error("Error marking employee as absconded.")
    //     }
    //     setLoading(false)
    // }

    const handleTerminateConfirmation = async () => {
        try {
            setTerminateModalOpen(false)
            toast.success("Employee terminated successfully")
            await loadData()
        } catch (error) {
            console.error("Error terminating employee:", error.message)
            toast.error("Error terminating employee")
        }
    }

    const handleTerminateCancel = () => {
        setTerminateModalOpen(false)
    }

    const handleTerminate = async (empId) => {
        setTerminateEmpId(empId)
        setTerminateModalOpen(true)
    }

    const handlePageClick = async (newPage) => {
        setPage(newPage)
    }

    const handleItemsPerPageChange = async (newRowsPerPage) => {
        setQuantity(newRowsPerPage)
        setPage(1)
    }

    const handleButtonClick = (columnId, empId) => {
        setSelectedData(empId)
        setDetailsModalOpen(columnId)
    }

    const handleCloseModal = () => {
        setSelectedData(null)
        setDetailsModalOpen("")
    }

    // const openAbscondModal = (empId) => {
    //     setAbscondEmpId(empId)
    //     setAbscondModalOpen(true)
    // }

    // const closeAbscondModal = () => {
    //     setAbscondModalOpen(false)
    //     setAbscondEmpId(null)
    // }

    useEffect(() => {
        if (initialLoad) loadData()
        setInitialLoad(true)
    }, [page, quantity])

    useEffect(() => {
        initialization()
    }, [searchTerm, statusFilter])

    return (
        <Paper className="pagePaper">
            <span className="info">
                <h5>Existing Employees' Information</h5>
                <SearchBar searchText={"Search by name..."} onSearch={handleSearch} searchType={"name"} />
            </span>

            {/* Status Filter Buttons */}
            {/* <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "30px" }}>
                <Button
                    variant="contained"
                    onClick={() => handleFilter("all")}
                    sx={{
                        backgroundColor: statusFilter === "all" ? "#2596BE" : "transparent",
                        color: statusFilter === "all" ? "white" : "#2596BE",
                        marginRight: "10px",
                        "&:hover": { backgroundColor: "#2596BE", color: "white" },
                    }}
                >
                    All
                </Button>
                <Button
                    variant="contained"
                    onClick={() => handleFilter("Active")}
                    sx={{
                        backgroundColor: statusFilter === "Active" ? "#2596BE" : "transparent",
                        color: statusFilter === "Active" ? "white" : "#2596BE",
                        marginRight: "10px",
                        "&:hover": { backgroundColor: "#2596BE", color: "white" },
                    }}
                >
                    Active
                </Button>
                <Button
                    variant="contained"
                    onClick={() => handleFilter("Absconded")}
                    sx={{
                        backgroundColor: statusFilter === "Absconded" ? "#2596BE" : "transparent",
                        color: statusFilter === "Absconded" ? "white" : "#2596BE",
                        "&:hover": { backgroundColor: "#2596BE", color: "white" },
                    }}
                >
                    Absconded
                </Button>
            </div> */}

            <div className="row" style={{
                paddingTop: "60px",
            }}>
                <div className="col-md-12">
                    <TableContainer
                        key={statusFilter}
                        sx={{
                            overflowY: "auto",
                            maxHeight: "500px"
                        }}
                    >
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            className="tableHeader"
                                            key={column.id}
                                            align="center"
                                            style={{
                                                minWidth: column.minWidth,
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    loader ? (
                                        <TableRow>
                                            <TableCell colSpan={11} style={{ padding: "30px 0", textAlign: "center" }}>
                                                <CircularProgress size={100} />
                                            </TableCell>
                                        </TableRow>
                                    ) : data && data.length > 0 ? (
                                        data.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="center" className="p-0">{item.empid}</TableCell>
                                                <TableCell align="center" className="p-0">{item.name}</TableCell>
                                                {columns.slice(2, columns.length).map((column) => (
                                                    <TableCell className="p-0" key={column.id} align="center">
                                                        {column.id === "actions" ? (
                                                            <div>
                                                                <Button
                                                                    variant="contained"
                                                                    onClick={() => navigation(`/employee/edit-employee/${item.empid}`)}
                                                                    style={{
                                                                        ...actionButtonStyle,
                                                                        backgroundColor: "orange",
                                                                        fontSize: "10px",
                                                                    }}
                                                                >
                                                                    Edit
                                                                </Button>
                                                                <Button
                                                                    variant="contained"
                                                                    onClick={() => handleTerminate(item.empid)}
                                                                    style={{
                                                                        ...actionButtonStyle,
                                                                        backgroundColor: "red",
                                                                        fontSize: "10px",
                                                                    }}
                                                                >
                                                                    Deactivate
                                                                </Button>
                                                                {/* {!item.absconded && (
                                                                    <Button
                                                                        variant="contained"
                                                                        onClick={() => openAbscondModal(item.empid)}
                                                                        style={{
                                                                            ...actionButtonStyle,
                                                                            backgroundColor: "#ED6C02",
                                                                            fontSize: "10px",
                                                                        }}
                                                                    >
                                                                        Mark as Absconded
                                                                    </Button>
                                                                )} */}
                                                            </div>
                                                        ) : (
                                                            <Button
                                                                variant="contained"
                                                                onClick={() => handleButtonClick(column.id, item.empid)}
                                                                style={{
                                                                    ...actionButtonStyle,
                                                                    backgroundColor: "#2596BE",
                                                                    fontSize: "10px",
                                                                }}
                                                            >
                                                                View
                                                            </Button>
                                                        )}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={11} style={{ padding: "30px 0", textAlign: "center" }}>
                                                No data found
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <PaginationTable
                        totalItems={totalItems}
                        itemsPerPage={quantity}
                        onPageChange={handlePageClick}
                        onItemsPerPageChange={handleItemsPerPageChange}
                    />

                    <TerminateModal
                        open={terminateModalOpen}
                        onClose={handleTerminateCancel}
                        onConfirm={handleTerminateConfirmation}
                        empId={terminateEmpId}
                    />

                </div>
            </div>

            {/* Rendering Modals with their selected row data */}
            <PersonalDetailsModal
                open={detailsModalOpen === "personalDetails"}
                onClose={handleCloseModal}
                empId={selectedData}
            />

            <EmployeeDetailsModal
                open={detailsModalOpen === "employeeDetails"}
                onClose={handleCloseModal}
                empId={selectedData}
            />

            <JoiningDetailsModal
                open={detailsModalOpen === "joiningDetails"}
                onClose={handleCloseModal}
                empId={selectedData}
            />

            <VisaDetailsModal
                open={detailsModalOpen === "visaDetails"}
                onClose={handleCloseModal}
                empId={selectedData}
            />

            <ContactDetailsModal
                open={detailsModalOpen === "contactDetails"}
                onClose={handleCloseModal}
                empId={selectedData}
            />

            <SalaryDetailsModal
                open={detailsModalOpen === "salaryDetails"}
                onClose={handleCloseModal}
                empId={selectedData}
            />

            <ContractDetailsModal
                open={detailsModalOpen === "contractDetails"}
                onClose={handleCloseModal}
                empId={selectedData}
            />

            <DocumentDetailsModal
                open={detailsModalOpen === "documentDetails"}
                onClose={handleCloseModal}
                empId={selectedData}
            />

            {/* Abscond Confirmation Modal */}
            {/* <Dialog
                open={abscondModalOpen}
                onClose={closeAbscondModal}
                aria-labelledby="abscond-modal-title"
                aria-describedby="abscond-modal-description"
            >
                <DialogTitle id="abscond-modal-title">Confirm Action</DialogTitle>
                <DialogContent>
                    <DialogContentText id="abscond-modal-description">
                        Are you sure you want to mark this employee as absconded?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "space-between", padding: "0 20px 20px" }}>
                    <Button
                        style={{ backgroundColor: "#FF0000", color: "white", width: "60px", height: "35px" }}
                        onClick={closeAbscondModal}
                        disabled={loading}
                    >
                        No
                    </Button>
                    <Button
                        sx={{
                            backgroundColor: 'primary.main',
                            color: 'white',
                            '&:hover': { backgroundColor: 'primary.dark' },
                            width: '60px',
                            height: '35px',
                        }}
                        onClick={handleMarkAsAbsconded}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : "Yes"}
                    </Button>
                </DialogActions>
            </Dialog> */}

        </Paper>
    )
}

