import React, { useState, useEffect } from "react"
import {
    Modal,
    Box,
    TextField,
    MenuItem,
    Button,
    CircularProgress,
    Typography,
    Stack,
    DialogContentText,
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import { fetchEmployeesForLeaveApplication } from "../../../Actions/commonController"
import { useAuth } from "../../../Context/authContext"
import { toast } from "react-toastify"

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
}

export default function EmployeeSelectionForm({ open, onClose }) {
    const [employeeList, setEmployeeList] = useState([])
    const [selectedEmployee, setSelectedEmployee] = useState("")
    const [loading, setLoading] = useState(false)
    const { eid } = useAuth()
    const navigate = useNavigate()

    const fetchEmployeeList = async () => {
        let APIData = {
            empid: eid
        }
        try {
            const { status, data } = await fetchEmployeesForLeaveApplication(APIData)
            if (status === "success") {
                const employees = data.map(employee => ({
                    ...employee,
                    EmployeeID: employee.EmployeeID ? employee.EmployeeID.toString() : employee.empid.toString(),
                    displayName: employee.role === "Self" ? `${employee.EmployeeName}` : employee.name,
                }))
                setEmployeeList(employees)
            } else {
                console.error("Failed to fetch employee list:", status)
            }
        } catch (error) {
            console.error("Error fetching employee list:", error)
        }
    }

    const handleEmployeeSelect = (e) => {
        setSelectedEmployee(e.target.value)
    }

    const handleSubmit = () => {
        setLoading(true)
        if (selectedEmployee) {
            onClose()
            navigate(`/leave/leave-request-form/${selectedEmployee}`)
        }
        else {
            toast.error("Select an employee to apply leave for!")
            setLoading(false)

        }
        setLoading(false)
    }

    useEffect(() => {
        if (open) {
            fetchEmployeeList()
        }
    }, [open, eid])

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={modalStyle}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Leave Request Application
                </Typography>
                <DialogContentText sx={{ paddingBottom: "20px" }}>
                    Select an employee to apply leave for.
                </DialogContentText>
                <Stack spacing={2}>
                    <TextField
                        select
                        label="Select Employee"
                        value={selectedEmployee}
                        onChange={handleEmployeeSelect}
                        fullWidth
                    >
                        <MenuItem value="" disabled>Select Employee</MenuItem>
                        {employeeList.map((employee) => (
                            <MenuItem key={employee.EmployeeID} value={employee.EmployeeID}>
                                {employee.displayName}
                            </MenuItem>
                        ))}
                    </TextField>
                    <div className="savebtn">
                        <Button onClick={handleSubmit} disable={loading}>
                            {loading ? (
                                <CircularProgress size={24} style={{ color: "white" }} />
                            ) : (
                                "Proceed"
                            )}
                        </Button>
                    </div>
                </Stack>
            </Box>
        </Modal>
    )
}