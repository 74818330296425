import React, { useState, useEffect } from "react"
import {
    MenuItem,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    CircularProgress,
} from "@mui/material"
import { toast } from "react-toastify"
import {
    fetchDropdownOptions,
    addDropdownOption,
} from "../../Actions/commonController"

const Dropdown = ({ fieldName, dropdownOptions, setDropdownOptions, value, onChange, tier }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [newOption, setNewOption] = useState("")
    const [loader, setLoader] = useState(false)

    const handleSelection = (e) => {
        const selectedValue = e.target.value
        if (selectedValue === "addNew") {
            setIsDialogOpen(true)
        } else {
            onChange(fieldName, selectedValue)
        }
    }

    const handleDialogClose = () => {
        setIsDialogOpen(false)
        setNewOption("")
    }

    const handleAddOption = async () => {
        if (!newOption.trim()) {
            toast.error("Option cannot be empty");
            return;
        }

        setLoader(true);

        try {
            const addResponse = await addDropdownOption({ fieldname: fieldName, newOption });

            if (addResponse.status === "success") {
                toast.success(addResponse.message);

                const fetchResponse = await fetchDropdownOptions();
                if (fetchResponse.status === "success") {
                    const updatedOptions = fetchResponse.data;

                    setDropdownOptions((prev) => ({
                        ...prev,
                        [fieldName]: updatedOptions[fieldName],
                    }));

                    if (fieldName === "linemanager") {
                        const addedManager = updatedOptions[fieldName].find(
                            (manager) => manager.name === newOption
                        );
                        if (addedManager) {
                            onChange(fieldName, addedManager.empid);
                        }
                    } else {
                        onChange(fieldName, newOption);
                    }
                } else {
                    toast.error("Failed to fetch updated options.");
                }
            } else {
                toast.error(addResponse.message || "Failed to add option.");
            }
        } catch (error) {
            console.error("Error adding option:", error);
            toast.error("An error occurred while adding the option.");
        } finally {
            setLoader(false);
            handleDialogClose();
        }
    };


    return (
        <>
            <TextField
                select
                variant="standard"
                value={value || ""}
                onChange={handleSelection}
                fullWidth
            >
                {dropdownOptions.map((option, index) => (
                    <MenuItem key={index} value={option.empid || option}>
                        {option.name || option}
                    </MenuItem>
                ))}
                {tier >= 2 && (
                    <MenuItem value="addNew" style={{ fontWeight: "bold" }}>
                        + New Item
                    </MenuItem>
                )}
            </TextField>

            {/* Dialog for adding a new option */}
            <Dialog open={isDialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Add New Option</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="New Option"
                        type="text"
                        value={newOption}
                        onChange={(e) => setNewOption(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions sx={{ display: "flex", justifyContent: "space-between", padding: "0px 20px 20px 20px" }}>
                    <Button
                        sx={{ backgroundColor: "red", color: "white", "&:hover": { backgroundColor: "darkred" }, width: "80px", height: "35px" }}
                        onClick={handleDialogClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleAddOption}
                        color="primary"
                        disabled={loader}
                        sx={{ backgroundColor: "#2596BE", color: "white", "&:hover": { backgroundColor: "#1c7da0" }, height: "35px" }}
                    >
                        {loader ? (
                            <CircularProgress size={24} style={{ color: "white" }} />
                        ) : (
                            "Add"
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Dropdown
