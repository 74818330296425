import React, { useState, useEffect } from "react"
import {
  Grid,
  Paper,
  Box,
  TextField,
  MenuItem,
  Button,
  CircularProgress,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputAdornment,
} from "@mui/material"
import {
  addEmp,
  empCheck,
  fetchDropdownOptions,
} from "../../Actions/commonController"
import { toast } from "react-toastify"
import DragNDrop from "../dragNDrop"
import { Validate } from "../Validations/AddEmployeeValidation"
import { useAuth } from "../../Context/authContext"
import nationalities from "../../json/nationalities.json"
import countryCodes from "../../json/countryCodes.json"
import { useNavigate } from "react-router-dom"
import { AdjustFilename } from "../Utilities/FilenameAdjuster"
import ResponsiveDatePicker from "../DatePicker/ResponsiveDatePicker"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import Dropdown from "../Modals/Dropdown"

const initialValue = {
  name: "",
  email: "",
  gender: "",
  nationality: "",
  dob: "",
  age: "",
  passportno: "",
  passportstatus: "",
  passportexpiry: "",
  contactuae: "",
  maritalstatus: "",
  religion: "",
  photoloc: [],
  dateofjoin: "",
  designation: "",
  officeloc: "",
  bankname: "",
  dept: "",
  division: "",
  hiredfrom: "",
  sourcecode: "",
  linemanager: "",
  teamleader: "",
  visaid: "",
  dateofjoinmol: "",
  workpermitnumber: "",
  workpermitstartdate: "",
  workpermitexpiry: "",
  personalcodemohre: "",
  contracttype: "",
  sponsorname: "",
  labourcard: "",
  visafileno: "",
  visadesignation: "",
  visatype: "",
  visaexpiry: "",
  visacancellation: "",
  eidno: "",
  gpssa: "",
  insuredno: "",
  insureddate: "",
  contacthome: "",
  emergencycontactuaename: "",
  emergencycontactuaeno: "",
  emergencycontacthomename: "",
  emergencycontacthomeno: "",
  companysimnumber: "",
  simissuedate: "",
  simlastdate: "",
  simplan: "",
  medicalprovider: "",
  medicalexpiry: "",
  medicalcardno: "",
  basicsalary: "",
  housingallowance: "",
  transportallowance: "",
  foodallowance: "",
  mobileallowance: "",
  otherallowance: "",
  totalsalary: "",
  contractsalary: "",
  accnumber: "",
  iban: "",
  salarybankname: "",
  folderid: "",
  remarks: "",
  docslist: [],
  docstatus: "",
  previousempemail: "",
  verificationstatus: "",
  emailsenton: "",
  repliedon: "",
}

const documentMappingInitial = {
  photo: {
    name: "Photo",
    keywords: ["photo"]
  },
  passportCopy: {
    name: "Passport Copy",
    keywords: ["passport"]
  },
  attestedDegreeCertificate: {
    name: "Attested Degree Certificate or Highest Qualification Certificate",
    keywords: ["attested", "degree", "qualification"]
  },
  visitVisaCopy: {
    name: "Visit Visa Copy or Visa Cancellation Documents",
    keywords: ["visit visa", "visa cancellation", "current visa", "visa copy", "employment visa"]
  },
  experienceCertificate: {
    name: "Experience Certificate or Acceptance of Resignation Letter",
    keywords: ["experience certificate", "experience letter", "resignation"]
  },
  emiratesID: {
    name: "Emirates ID",
    keywords: ["emirates id", "eid"]
  },
  validHomeCountryID: {
    name: "Valid Home Country ID",
    keywords: ["home country id", "pakistan", "india", "egypt"]
  },
  cv: {
    name: "CV",
    keywords: ["cv"]
  },
  tradeLicense: {
    name: "Trade License",
    keywords: ["trade license"]
  },
  policeClearance: {
    name: "Police Clearance",
    keywords: ["police", "clearance"]
  },
  eMF: {
    name: "eMF",
    keywords: ["emf"]
  },
  sponsorPassport: {
    name: "Sponsor Passport",
    keywords: ["sponsor passport"]
  },
  sponsorVisaCopy: {
    name: "Sponsor Visa Copy",
    keywords: ["sponsor visa"]
  },
  noc: {
    name: "NOC in Arabic addressing Fintrek Marketing",
    keywords: ["noc", "fintrek marketing"]
  },
}

const defaultDocumentList = Object.keys(documentMappingInitial)

export default function AddEmployeeForm() {
  const [formValue, setFormValue] = useState(initialValue)
  const [formValidation, setFormValidation] = useState({})
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [loader, setLoader] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [dialogMessage, setDialogMessage] = useState("")
  const [existingEmployee, setExistingEmployee] = useState(null)
  const [documentStatus, setDocumentStatus] = useState("Pending")
  const { eid, tier } = useAuth()
  const navigate = useNavigate()
  const [newDoc, setNewDoc] = useState("")
  const [requiredDocuments, setRequiredDocuments] = useState(defaultDocumentList)
  const [documentMapping, setDocumentMapping] = useState(documentMappingInitial)
  const [dropdownOptions, setDropdownOptions] = useState({
    dept: [],
    bankname: [],
    sponsorname: [],
    linemanager: [],
  })

  const {
    name, email, gender, nationality, dob, age, passportno, passportstatus, passportexpiry,
    contactuae, maritalstatus, religion, photoloc, hiredfrom, dateofjoin, designation, officeloc,
    bankname, dept, division, sourcecode, linemanager, teamleader, visaid, dateofjoinmol,
    workpermitnumber, workpermitstartdate, workpermitexpiry, personalcodemohre, contracttype, sponsorname,
    labourcard, visafileno, visadesignation, visatype, visaexpiry, visacancellation, eidno, gpssa, insuredno,
    insureddate, contacthome, emergencycontactuaename, emergencycontactuaeno, emergencycontacthomename,
    emergencycontacthomeno, companysimnumber, simissuedate, simlastdate, simplan, medicalprovider,
    medicalexpiry, medicalcardno, basicsalary, housingallowance, transportallowance, foodallowance,
    mobileallowance, otherallowance, totalsalary, contractsalary, accnumber, iban, salarybankname,
    remarks, previousempemail, verificationstatus, emailsenton, repliedon,
  } = formValue || {}


  const handleChange = (e) => {
    let { name, value } = e.target

    if (name === "name") {
      const alphabetRegex = /^[A-Za-z\s]*$/
      if (!alphabetRegex.test(value)) {
        return
      }
    }

    const numberFields = [
      "contactuae",
      "contacthome",
      "emergencycontactuaeno",
      "emergencycontacthomeno",
      "companysimnumber",
      "basicsalary",
      "housingallowance",
      "transportallowance",
      "foodallowance",
      "mobileallowance",
      "otherallowance",
      "contractsalary",
    ]

    if (numberFields.includes(name)) {
      const numberRegex = /^[0-9+()\-\s]*$/
      if (!numberRegex.test(value)) {
        return
      }
    }

    let updatedValues = { ...formValue, [name]: value }

    if (name === "dob") {
      const parsedDate = parseDate(value)
      if (parsedDate) {
        const calculatedAge = calculateAge(parsedDate)
        updatedValues.age = calculatedAge

        if (calculatedAge < 18) {
          toast.error("Employee must be at least 18 years old")
        }
      } else {
        updatedValues.age = ""
      }
    }
    setFormValue(updatedValues)
  }

  const handleDropdownChange = (fieldName, selectedValue) => {
    setFormValue((prev) => ({
      ...prev,
      [fieldName]: selectedValue,
    }));
  };

  function parseDate(dateStr) {
    const parts = dateStr.split("/")
    if (parts.length !== 3) return null
    const [day, month, year] = parts.map(part => parseInt(part, 10))
    if (!day || !month || !year) return null
    return new Date(year, month - 1, day)
  }

  function calculateAge(dob) {
    const today = new Date()
    const birthDate = new Date(dob)
    let age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age
  }

  const handleNationalityChange = (event, newValue) => {

    setFormValue({
      ...formValue,
      nationality: newValue || "",
    })
  }

  useEffect(() => {
    const totalSalary = (
      parseFloat(basicsalary || 0) +
      parseFloat(housingallowance || 0) +
      parseFloat(transportallowance || 0) +
      parseFloat(foodallowance || 0) +
      parseFloat(mobileallowance || 0) +
      parseFloat(otherallowance || 0)
    ).toFixed(2)

    setFormValue(prevState => ({
      ...prevState,
      totalsalary: totalSalary.toString()
    }))
  }, [basicsalary, housingallowance, transportallowance, foodallowance, mobileallowance, otherallowance])

  useEffect(() => {
    setRequiredDocuments(defaultDocumentList)
  }, [])

  useEffect(() => {
    getDropDownOptions()
  }, [])

  const checkDocumentsStatus = (uploadedFiles) => {
    const uploadedFileNames = uploadedFiles.map(file => file.name.toLowerCase())

    const missingDocuments = requiredDocuments.filter(docKey => {
      const doc = documentMapping[docKey]
      if (!doc) {
        console.warn(`Document key ${docKey} not found in documentMapping`)
        return false
      }

      const keywords = doc.keywords
      return !uploadedFileNames.some(fileName =>
        keywords.some(keyword => fileName.includes(keyword))
      )
    })

    return missingDocuments.length === 0 ? "Submitted" : "Pending"
  }

  const handleDobBlur = () => {
    if (formValue.age < 18) {
      toast.error("Employee must be at least 18 years old")
    }
  }

  const handleSubmit = async () => {

    try {

      setLoader(true)

      const docStatus = updateDocumentStatus(uploadedFiles)

      let APIData = {
        name: name,
        email: email,
        gender: gender,
        nationality: nationality,
        dob: dob,
        age: age,
        passportno: passportno,
        passportstatus: passportstatus,
        passportexpiry: passportexpiry,
        contactuae: contactuae,
        maritalstatus: maritalstatus,
        religion: religion,
        dateofjoin: dateofjoin,
        designation: designation,
        officeloc: officeloc,
        bankname: bankname,
        dept: dept,
        division: division,
        hiredfrom: hiredfrom,
        sourcecode: sourcecode,
        linemanager: linemanager,
        teamleader: teamleader,
        visaid: visaid,
        dateofjoinmol: dateofjoinmol,
        workpermitnumber: workpermitnumber,
        workpermitstartdate: workpermitstartdate,
        workpermitexpiry: workpermitexpiry,
        personalcodemohre: personalcodemohre,
        contracttype: contracttype,
        sponsorname: sponsorname,
        labourcard: labourcard,
        visafileno: visafileno,
        visadesignation: visadesignation,
        visatype: visatype,
        visaexpiry: visaexpiry,
        visacancellation: visacancellation,
        eidno: eidno,
        gpssa: gpssa,
        insuredno: insuredno,
        insureddate: insureddate,
        contacthome: contacthome,
        emergencycontactuaename: emergencycontactuaename,
        emergencycontactuaeno: emergencycontactuaeno,
        emergencycontacthomename: emergencycontacthomename,
        emergencycontacthomeno: emergencycontacthomeno,
        companysimnumber: companysimnumber,
        simissuedate: simissuedate,
        simlastdate: simlastdate,
        simplan: simplan,
        medicalprovider: medicalprovider,
        medicalexpiry: medicalexpiry,
        medicalcardno: medicalcardno,
        basicsalary: basicsalary,
        housingallowance: housingallowance,
        transportallowance: transportallowance,
        foodallowance: foodallowance,
        mobileallowance: mobileallowance,
        otherallowance: otherallowance,
        totalsalary: totalsalary,
        contractsalary: contractsalary,
        accnumber: accnumber,
        iban: iban,
        salarybankname: salarybankname,
        empid: eid,
        remarks: remarks,
        docslist: requiredDocuments.map(docKey => documentMapping[docKey]?.name),
        docstatus: docStatus,
        previousempemail: previousempemail,
        verificationstatus: verificationstatus,
        emailsenton: emailsenton,
        repliedon: repliedon,
      }

      let APIFiles = {
        documents: uploadedFiles,
        photoloc: photoloc,
      }

      let checkValidate = Validate(APIData)
      setFormValidation(checkValidate)
      if (Object.entries(checkValidate).length > 0) {
        toast.error("Errors: " + Object.entries(checkValidate).map(([field, message]) => `${message}`).join(", "))
        setLoader(false)
        return
      }

      let existingEmpCheck = {
        name: name,
        eidno: eidno,
      }
      const { data } = await empCheck(existingEmpCheck)
      if (data) {
        setExistingEmployee(data)
        setDialogMessage(`Employee ${name} already exists with the EMP ID of ${data.empid}. `)
        setOpenDialog(true)
        setLoader(false)
        return
      }

      let { status, message, error } = await addEmp(APIData, APIFiles)
      if (status == "success") {
        navigate("/employee/new-employee-list")
        toast.success(message)
      } else toast.error(error)
    } catch (err) {
      toast.error(err.message)
    }
    setLoader(false)
  }

  const getDropDownOptions = async () => {
    try {
      const { status, data } = await fetchDropdownOptions()
      if (status === "success") {
        setDropdownOptions(data)
      } else {
        toast.error("Failed to fetch dropdown options")
      }
    }
    catch (error) {
      console.error("Error fetching dropdown options:", error)
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file && isValidFileType(file)) {
      setFormValue({ ...formValue, photoloc: file })
    } else {
      console.warn(`Invalid file selected`)
    }
  }

  const handleDrop = (acceptedFiles) => {
    let newUploadedFiles = [...uploadedFiles]
    acceptedFiles.forEach(file => {
      let adjustedFilename = AdjustFilename(file.name, uploadedFiles)
      let newFile = new File([file], adjustedFilename, { type: file.type })
      newUploadedFiles.push(newFile)
    })
    setUploadedFiles(newUploadedFiles)
    updateDocumentStatus(newUploadedFiles)
  }

  const isValidFileType = (file) => {
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"]
    return allowedTypes.includes(file.type)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const isDocumentUploaded = (documentKey) => {

    const doc = documentMapping[documentKey]
    if (!doc) {
      console.warn(`Document key ${documentKey} not found in documentMapping`)
      return false
    }

    const keywords = doc.keywords

    return uploadedFiles.some(file =>
      keywords.some(keyword => file.name.toLowerCase().includes(keyword))
    )
  }

  const addRequiredDocument = () => {
    if (newDoc.includes(";")) {
      toast.error("Semicolons are not allowed in document names.")
    } else if (newDoc && !Object.values(documentMapping).map(doc => doc.name).includes(newDoc)) {
      const newKey = newDoc.replace(/\s+/g, '').toLowerCase()
      const updatedDocumentMapping = {
        ...documentMapping,
        [newKey]: { name: newDoc, keywords: [newDoc.toLowerCase()] }
      }
      const updateDocuments = [...requiredDocuments, newKey]
      setRequiredDocuments(updateDocuments)
      setDocumentMapping(updatedDocumentMapping)
      setNewDoc("")
      updateDocumentStatus(uploadedFiles)
    }
  }

  const removeRequiredDocument = (docKey) => {
    if (documentMapping[docKey]) {
      const updatedDocumentMapping = { ...documentMapping }
      const updateDocuments = requiredDocuments.filter(doc => doc !== docKey)
      setRequiredDocuments(updateDocuments)
      delete updatedDocumentMapping[docKey]
      setDocumentMapping(updatedDocumentMapping)
      updateDocumentStatus(uploadedFiles)
    } else {
      console.warn(`Document key ${docKey} not found in documentMapping during removal`)
    }
  }

  const updateDocumentStatus = (uploadedFiles) => {
    const docStatus = checkDocumentsStatus(uploadedFiles)
    setDocumentStatus(docStatus)
    return docStatus
  }

  const handleRemoveFile = (index) => {
    const updatedFiles = [...uploadedFiles]
    updatedFiles.splice(index, 1)
    setUploadedFiles(updatedFiles)
    updateDocumentStatus(updatedFiles)
  }

  return (
    <Box>

      {/* Dialog for existing employee */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Employee Exists</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} variant="contained" color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* row 1 */}
      <Box className="itemBox">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className="itemPaper">
              <h3 className="cardTitle">PERSONAL INFORMATION</h3>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={5} lg={5} xl={4}>
                  <div className={`label ${formValidation && formValidation.name ? formValidation.name : ""}`}>Full Name *</div>
                  <TextField className={`TextField ${formValidation && formValidation.name ? formValidation.name : ""}`} variant="standard" name="name" value={name} onChange={handleChange} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <div className="label">E-mail</div>
                  <TextField variant="standard" name="email" value={email} onChange={handleChange} fullWidth />
                </Grid>
                <Grid item xs={12} sm={5} md={3} lg={3} xl={2}>
                  <div className={`label ${formValidation && formValidation.contactuae ? formValidation.contactuae : ""}`}>Contact No. *</div>
                  <TextField className={`TextField ${formValidation && formValidation.contactuae ? formValidation.contactuae : ""}`} variant="standard" name="contactuae" value={contactuae} onChange={handleChange} fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span>+971</span>
                        </InputAdornment>
                      ),
                    }} />
                </Grid>
                <Grid item xs={12} sm={5} md={3} lg={2} xl={2}>
                  <div className={`label ${formValidation && formValidation.dob ? formValidation.dob : ""}`}>Date of Birth *</div>
                  <ResponsiveDatePicker
                    name="dob"
                    className="employeeForm"
                    defValue={dob}
                    variant="standard"
                    handleDate={handleChange}
                    onBlur={handleDobBlur}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={1} lg={1} xl={1}>
                  <div className={`label ${formValidation && formValidation.age ? formValidation.age : ""}`}>Age *</div>
                  <TextField className={`TextField ${formValidation && formValidation.age ? formValidation.age : ""}`} variant="standard" name="age" value={age} onChange={handleChange} fullWidth disabled />
                </Grid>
                <Grid item xs={12} sm={7} md={4} lg={3} xl={2}>
                  <div className={`label ${formValidation && formValidation.nationality ? formValidation.nationality : ""}`}>Nationality *</div>
                  <Autocomplete
                    disablePortal
                    name="nationality"
                    value={nationality}
                    onChange={handleNationalityChange}
                    options={nationalities}
                    renderInput={(params) => <TextField {...params} variant="standard" />}
                  />
                </Grid>
                <Grid item xs={12} sm={5} md={2} lg={2} xl={2}>
                  <div className={`label ${formValidation && formValidation.gender ? formValidation.gender : ""}`}>Gender *</div>
                  <TextField select variant="standard" name="gender" value={gender} onChange={handleChange} fullWidth>
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                  <div className="label">Marital Status</div>
                  <TextField select variant="standard" name="maritalstatus" value={maritalstatus} onChange={handleChange} fullWidth>
                    <MenuItem value="Single">Single</MenuItem>
                    <MenuItem value="Married">Married</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                  <div className="label">Religion</div>
                  <TextField variant="standard" name="religion" value={religion} onChange={handleChange} fullWidth />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={6} xl={3}>
                  <div className="label">Remarks</div>
                  <TextField variant="standard" name="remarks" value={remarks} onChange={handleChange} fullWidth />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      {/* row 2 */}
      <Box className="itemBox">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className="itemPaper">
              <h3 className="cardTitle">PASSPORT & VISA INFORMATION</h3>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                  <div className="label">Passport No.</div>
                  <TextField variant="standard" name="passportno" value={passportno} onChange={handleChange} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                  <div className="label">Passport Status</div>
                  <TextField select variant="standard" name="passportstatus" value={passportstatus} onChange={handleChange} fullWidth>
                    <MenuItem value="With Company">With Company</MenuItem>
                    <MenuItem value="Employee">Employee</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                  <div className="label">Passport Expiry</div>
                  <ResponsiveDatePicker
                    name="passportexpiry"
                    className="employeeForm"
                    defValue={passportexpiry}
                    handleDate={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
                  <div className="label">Sponsor Name</div>
                  <Dropdown
                    fieldName="sponsorname"
                    dropdownOptions={dropdownOptions.sponsorname}
                    onChange={handleDropdownChange}
                    value={formValue.sponsorname}
                    setDropdownOptions={setDropdownOptions}
                    tier={tier}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
                  <div className="label">Labour Card Under</div>
                  <TextField variant="standard" name="labourcard" value={labourcard} onChange={handleChange} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                  <div className="label">Contract Type</div>
                  <TextField select variant="standard" name="contracttype" value={contracttype} onChange={handleChange} fullWidth>
                    <MenuItem value="Limited">Limited</MenuItem>
                    <MenuItem value="Labour Card">Labour Card</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
                  <div className="label">Visa File No.</div>
                  <TextField variant="standard" name="visafileno" value={visafileno} onChange={handleChange} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                  <div className="label">UID</div>
                  <TextField variant="standard" name="visaid" value={visaid} onChange={handleChange} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
                  <div className="label">Visa Designation</div>
                  <TextField variant="standard" name="visadesignation" value={visadesignation} onChange={handleChange} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                  <div className="label">Visa Status</div>
                  <TextField select variant="standard" name="visatype" value={visatype} onChange={handleChange} fullWidth>
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">Inactive</MenuItem>
                    <MenuItem value="Cancelled">Cancelled</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                  <div className="label">Visa Expiry</div>
                  <ResponsiveDatePicker
                    name="visaexpiry"
                    className="employeeForm"
                    defValue={visaexpiry}
                    handleDate={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                  <div className="label">Emirates ID No.</div>
                  <TextField variant="standard" name="eidno" value={eidno} onChange={handleChange} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                  <div className="label">Work Permit No.</div>
                  <TextField variant="standard" name="workpermitnumber" value={workpermitnumber} onChange={handleChange} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                  <div className="label">Work Permit Expiry</div>
                  <ResponsiveDatePicker
                    name="workpermitexpiry"
                    className="employeeForm"
                    defValue={workpermitexpiry}
                    handleDate={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                  <div className="label">Personal Code (MOHRE)</div>
                  <TextField variant="standard" name="personalcodemohre" value={personalcodemohre} onChange={handleChange} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                  <div className="label">Medical Insurance Provider</div>
                  <TextField variant="standard" name="medicalprovider" value={medicalprovider} onChange={handleChange} fullWidth>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                  <div className="label">Medical Insurance Card No.</div>
                  <TextField variant="standard" name="medicalcardno" value={medicalcardno} onChange={handleChange} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                  <div className="label">Medical Insurance Expiry</div>
                  <ResponsiveDatePicker
                    name="medicalexpiry"
                    className="employeeForm"
                    defValue={medicalexpiry}
                    handleDate={handleChange}
                  />
                </Grid>
                {/* Additional fields */}
                {formValue.nationality === "U.A.E" && (
                  <>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                      <div className="label">GPSSA</div>
                      <TextField select variant="standard" name="gpssa" value={gpssa} onChange={handleChange} fullWidth>
                        <MenuItem value="123">123</MenuItem>
                        <MenuItem value="456">456</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                      <div className="label">Insured No.</div>
                      <TextField
                        variant="standard"
                        name="insuredno"
                        value={insuredno}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                      <div className="label">Insured Date</div>
                      <ResponsiveDatePicker
                        name="insureddate"
                        className="employeeForm"
                        defValue={insureddate}
                        handleDate={handleChange}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box >

      {/* row 3 */}
      < Box className="itemBox" >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className="itemPaper">
              <h3 className="cardTitle">EMPLOYMENT DETAILS</h3>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                  <div className="label">Date of Join (Actual)</div>
                  <ResponsiveDatePicker
                    name="dateofjoin"
                    className="employeeForm"
                    defValue={dateofjoin}
                    handleDate={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                  <div className="label">MOL Start Date</div>
                  <ResponsiveDatePicker
                    name="dateofjoinmol"
                    className="employeeForm"
                    defValue={dateofjoinmol}
                    handleDate={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                  <div className="label">Hired from</div>
                  <TextField select variant="standard" name="hiredfrom" value={hiredfrom} onChange={handleChange} fullWidth>
                    <MenuItem value="Outside">Outside</MenuItem>
                    <MenuItem value="Inside">Inside</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                  <div className="label">Office Location</div>
                  <TextField variant="standard" name="officeloc" value={officeloc} onChange={handleChange} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                  <div className="label">Designation</div>
                  <TextField variant="standard" name="designation" value={designation} onChange={handleChange} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>

                  <div className="label">Department</div>
                  <Dropdown
                    fieldName="dept"
                    dropdownOptions={dropdownOptions.dept}
                    onChange={handleDropdownChange}
                    value={formValue.dept}
                    setDropdownOptions={setDropdownOptions}
                    tier={tier}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
                  <div className="label">Division</div>
                  <TextField select variant="standard" name="division" value={division} onChange={handleChange} fullWidth>
                    <MenuItem value="Cards">Cards</MenuItem>
                    <MenuItem value="Loans">Loans</MenuItem>
                    <MenuItem value="Admin">Admin</MenuItem>
                    <MenuItem value="IT">IT</MenuItem>
                    <MenuItem value="Accounts">Accounts</MenuItem>
                    <MenuItem value="Auto Loan">Auto Loan</MenuItem>
                    <MenuItem value="SME">SME</MenuItem>
                    <MenuItem value="Mortgage Loan">Mortgage Loan</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                  <div className="label">Line Manager</div>
                  <Dropdown
                    fieldName="linemanager"
                    dropdownOptions={dropdownOptions.linemanager || []}
                    value={formValue.linemanager}
                    onChange={handleDropdownChange}
                    setDropdownOptions={setDropdownOptions}
                    tier={tier}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <div className="label">Team Leader</div>
                  <TextField variant="standard" name="teamleader" value={teamleader} onChange={handleChange} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                  <div className="label">Source Code</div>
                  <TextField variant="standard" name="sourcecode" value={sourcecode} onChange={handleChange} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                  <div className="label">Bank Name</div>
                  <Dropdown
                    fieldName="bankname"
                    dropdownOptions={dropdownOptions.bankname}
                    onChange={handleDropdownChange}
                    value={formValue.bankname}
                    setDropdownOptions={setDropdownOptions}
                    tier={tier}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box >

      {/* row 4 */}
      < Box className="itemBox" >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className="itemPaper">
              <h3 className="cardTitle">CONTACT DETAILS</h3>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <div className="label">Emergency Contact Name (UAE)</div>
                  <TextField variant="standard" name="emergencycontactuaename" value={emergencycontactuaename} onChange={handleChange} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <div className="label">Emergency Contact No. (UAE)</div>
                  <TextField variant="standard" name="emergencycontactuaeno" value={emergencycontactuaeno} onChange={handleChange} fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span>+971</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <div className="label">Contact No. (Home Country)</div>
                  <TextField variant="standard" name="contacthome" value={contacthome} onChange={handleChange} fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span>{countryCodes[formValue.nationality] || "+"}</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <div className="label">Emergency Contact Name (Home Country)</div>
                  <TextField variant="standard" name="emergencycontacthomename" value={emergencycontacthomename} onChange={handleChange} fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <div className="label">Emergency Contact No. (Home Country)</div>
                  <TextField variant="standard" name="emergencycontacthomeno" value={emergencycontacthomeno} onChange={handleChange} fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span>{countryCodes[formValue.nationality] || "+"}</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <div className="label">Company SIM No.</div>
                  <TextField variant="standard" name="companysimnumber" value={companysimnumber} onChange={handleChange} fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span>+971</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                  <div className="label">SIM Issue Date</div>
                  <ResponsiveDatePicker
                    name="simissuedate"
                    className="employeeForm"
                    defValue={simissuedate}
                    variant="standard"
                    handleDate={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                  <div className="label">SIM Last Date</div>
                  <ResponsiveDatePicker
                    name="simlastdate"
                    className="employeeForm"
                    defValue={simlastdate}
                    variant="standard"
                    handleDate={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
                  <div className="label">SIM Plan</div>
                  <TextField variant="standard" name="simplan" value={simplan} onChange={handleChange} fullWidth />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box >

      {/* row 5 */}
      < Box className="itemBox" >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className="itemPaper">
              <h3 className="cardTitle">SALARY DETAILS</h3>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                  <div className="label">Basic Salary</div>
                  <TextField variant="standard" name="basicsalary" value={basicsalary} onChange={handleChange} fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span>/- AED</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                  <div className="label">Housing Allowance</div>
                  <TextField variant="standard" name="housingallowance" value={housingallowance} onChange={handleChange} fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span>/- AED</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                  <div className="label">Transport Allowance</div>
                  <TextField variant="standard" name="transportallowance" value={transportallowance} onChange={handleChange} fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span>/- AED</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                  <div className="label">Food Allowance</div>
                  <TextField variant="standard" name="foodallowance" value={foodallowance} onChange={handleChange} fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span>/- AED</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                  <div className="label">Mobile Allowance</div>
                  <TextField variant="standard" name="mobileallowance" value={mobileallowance} onChange={handleChange} fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span>/- AED</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                  <div className="label">Other Allowance</div>
                  <TextField variant="standard" name="otherallowance" value={otherallowance} onChange={handleChange} fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span>/- AED</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                  <div className="label">Total Salary</div>
                  <TextField variant="standard" name="totalSalary" value={totalsalary} onChange={handleChange} fullWidth disabled
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span>/- AED</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                  <div className="label">Contract Salary</div>
                  <TextField variant="standard" name="contractsalary" value={contractsalary} onChange={handleChange} fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span>/- AED</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={2} xl={2}>
                  <div className="label">Account No.</div>
                  <TextField variant="standard" name="accnumber" value={accnumber} onChange={handleChange} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <div className="label">IBAN</div>
                  <TextField variant="standard" name="iban" value={iban} onChange={handleChange} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <div className="label">Salary Bank Name</div>
                  <TextField variant="standard" name="salarybankname" value={salarybankname} onChange={handleChange} fullWidth />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box >

      {/* row 6 */}
      <Box className="itemBox">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className="itemPaper">
              <h3 className="cardTitle">BACKGROUND CHECK</h3>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                  <div className="label">Previous Employer's Email</div>
                  <TextField variant="standard" name="previousempemail" value={previousempemail} onChange={handleChange} fullWidth />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                  <div className="label">Verification Status</div>
                  <TextField select variant="standard" name="verificationstatus" value={verificationstatus} onChange={handleChange} fullWidth>
                    <MenuItem value="Sent">Sent</MenuItem>
                    <MenuItem value="In Process">In Process</MenuItem>
                    <MenuItem value="Completed">Completed</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                  <div className="label">Email Sent Date</div>
                  <ResponsiveDatePicker
                    name="emailsenton"
                    className="employeeForm"
                    defValue={emailsenton}
                    handleDate={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                  <div className="label">Email Received Date</div>
                  <ResponsiveDatePicker
                    name="repliedon"
                    className="employeeForm"
                    defValue={repliedon}
                    handleDate={handleChange}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      {/* row 7 */}
      < Box className="itemBox" >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Paper className="itemPaper" sx={{ height: "445px" }}>
              <h3 className="cardTitle"> DOCUMENT UPLOADS
                <span className="updatebtn" style={{ paddingLeft: "20px" }}>
                  {/* <Button onClick={() => setOpenDialog(true)} disabled={loader} style={{ width: "150px", borderRadius: "20px" }}>
                    + New Folder
                  </Button> */}
                </span>
              </h3>
              <DragNDrop onDrop={handleDrop} uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} handleRemoveFile={handleRemoveFile} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className="itemPaper requiredDocs">
              <h3 className="cardTitle">REQUIRED DOCUMENTS</h3>
              <ul className="requiredDocsList">
                {requiredDocuments.map((docKey) => (
                  <li className="requiredDocsItem" key={docKey}>
                    <div className="textContainer">
                      {isDocumentUploaded(docKey) ? (
                        <CheckCircleIcon style={{ color: 'green', marginRight: '8px' }} className="docIcon" />
                      ) : (
                        <Box
                          className="docIcon"
                          sx={{
                            width: 24,
                            height: 24,
                            border: '1px solid #ccc',
                            borderRadius: '50%',
                            marginRight: '8px',
                          }}
                        />
                      )}
                      {documentMapping[docKey].name}
                    </div>
                    <Button
                      className="removeButton"
                      onClick={() => removeRequiredDocument(docKey)}
                    >
                      Remove
                    </Button>
                  </li>
                ))}
              </ul>
              <Box className="addDocBox">
                <TextField
                  variant="standard"
                  placeholder="Add new document*"
                  value={newDoc}
                  onChange={(e) => setNewDoc(e.target.value)}
                  sx={{ marginRight: '8px', flexGrow: 1 }}
                  inputProps={{ style: { textTransform: 'none' } }}
                />
                <Button className="add-list" onClick={() => addRequiredDocument(newDoc)}>
                  Add
                </Button>
              </Box>
              <div style={{ fontSize: "11px", color: "red" }}>
                *The new document name added should match with the uploaded file name.
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Box >

      <Box className="itemBox">
        <div className="savebtn">
          <Button onClick={handleSubmit} disable={loader}>
            {loader ? (
              <CircularProgress size={24} style={{ color: "white" }} />
            ) : (
              "SAVE"
            )}
          </Button>
        </div>
      </Box>

    </Box >
  )
}