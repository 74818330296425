import React, { useState, useEffect } from "react"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { MobileDatePicker } from "@mui/x-date-pickers"
import { useMediaQuery, TextField } from "@mui/material"
import dayjs from "dayjs"
import 'dayjs/locale/en-gb'

export default function ResponsiveDatePicker({ name, label, defValue, handleDate, minDate, className, disabled }) {
  const isDesktop = useMediaQuery("(min-width:800px)")
  const [value, setValue] = useState(defValue ? dayjs(defValue, "DD/MM/YYYY") : null)

  useEffect(() => {
    if (defValue) {
      setValue(dayjs(defValue, "DD/MM/YYYY"))
    }
  }, [defValue])

  const handleDateChange = (newValue) => {
    const formattedData = newValue ? newValue.format("DD/MM/YYYY") : ""
    setValue(newValue)
    handleDate({ target: { name, value: formattedData } })
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      {isDesktop ? (
        <DesktopDatePicker
          label={label}
          className={className}
          minDate={minDate ? dayjs(minDate, "DD/MM/YYYY") : null}
          value={value}
          onChange={handleDateChange}
          inputFormat="DD/MM/YYYY"
          disabled={disabled}
          renderInput={(params) => <TextField {...params} placeholder="dd/mm/yyyy" />}
        />
      ) : (
        <MobileDatePicker
          label={label}
          className={className}
          minDate={minDate ? dayjs(minDate, "DD/MM/YYYY") : null}
          value={value}
          onChange={handleDateChange}
          inputFormat="DD/MM/YYYY"
          disabled={disabled}
          renderInput={(params) => <TextField {...params} placeholder="dd/mm/yyyy" />}
        />
      )}
    </LocalizationProvider>
  )
}