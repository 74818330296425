import { saveAs } from "file-saver"
import html2pdf from "html2pdf.js"
// import PizZip from "pizzip"
// import Docxtemplater from "docxtemplater"
// import jsPDF from "jspdf"
// import html2canvas from "html2canvas"
// import htmlToDocx from "html-to-docx"
// import mammoth from "mammoth"
// import htmlDocx from "html-docx-js/dist/html-docx"
import fintrekMarketingConsultanciesLLCLetterhead from "../../assets/salaryCertifTemplates/fintrekMarketingConsultanciesLLCLetterhead.png"
import fintrekMarketingConsultancyLetterhead from "../../assets/salaryCertifTemplates/fintrekMarketingConsultancyLetterhead.png"
import fintrekMarketingLetterhead from "../../assets/salaryCertifTemplates/fintrekMarketingLetterhead.png"
import neowizMarketingManagementLetterhead from "../../assets/salaryCertifTemplates/neowizMarketingManagementLetterhead.png"
import wizhullLetterhead from "../../assets/salaryCertifTemplates/wizhullLetterhead.png"
import { toast } from "react-toastify"

const getLetterHead = (sponsorname) => {
    switch (sponsorname) {
        case "Fintrek Marketing":
            return fintrekMarketingLetterhead
        case "Fintrek Marketing Consultancy":
            return fintrekMarketingConsultancyLetterhead
        case "Fintrek Marketing Consultancies LLC":
            return fintrekMarketingConsultanciesLLCLetterhead
        case "Neowiz Marketing Management":
            return neowizMarketingManagementLetterhead
        case "Wizhull Real Estate Mortgage Broker":
            return wizhullLetterhead
        default:
            return fintrekMarketingLetterhead
    }
}

const fetchImageAsBase64 = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
};

const toTitleCase = (str) => {
    return str
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
}


export const DownloadSalaryCertif = async (employee, today, title, pronoun, salaryInWords, format) => {

    const letterhead = getLetterHead(employee.sponsorname)
    const letterheadBase64 = await fetchImageAsBase64(letterhead)

    const content = `
    <html>
        <body>
            <div id="certificate-content" style="position: relative; font-family: 'Times', serif; font-size: 13pt; line-height: 1.5; width: 210mm; height: 297mm;">
            <!-- Background Letterhead -->
        
                <img src="${letterheadBase64}" alt="Header" style="position: absolute; width: 210mm; height: 297mm; top: 0; left: 0; z-index: 0;">

                <!-- Content on top of the Letterhead -->
                <div style="position: relative; z-index: 1; padding-top: 50mm; padding-left: 20mm; padding-right: 20mm; padding-bottom: 40mm;">
                    <div style="display: flex; justify-content: space-between; margin-bottom: 10px; ">
                        <p style="margin: 0;">To,</p>
                        <p style="margin: 0; text-align: right;">${today}</p>
                    </div>
                    
                    <div style="margin-bottom: 20px;">
                        <p style="margin: 0;">${employee.towhom}</p>
                    </div>
                    
                    <h2 style="text-align: center; font-family: 'Times', serif; font-size: 13pt; font-weight: bold; margin-top: 20px;">
                        SALARY CERTIFICATE
                    </h2>

                    <p style="font-family: 'Times', serif; font-size: 13pt; margin-top: 20px; text-align: justify;">
                        This is to certify that <strong>${title} ${employee.name}</strong> 
                        holding <strong>${employee.nationality}</strong> passport number 
                        <strong>${employee.passportno}</strong> is a confirmed employee of our 
                        company, working in the capacity of <strong>${employee.designation}</strong> 
                        since <strong>${employee.dateofjoin}</strong>. Moreover, ${pronoun} monthly 
                        gross salary is <strong>AED ${employee.totalsalary}/- 
                        (${toTitleCase(salaryInWords)} Dirhams Only)</strong>.
                    </p>

                    <p style="font-family: 'Times', serif; font-size: 13pt; margin-top: 20px; text-align: justify;">
                        We are issuing this letter on the specific request of <strong>${title} 
                        ${employee.name}</strong> under ${pronoun} risk and responsibility and our 
                        organization is not liable for any consequences thereof due to issuance 
                        of this letter.
                    </p>

                    <p style="margin-top: 20px;">Yours Sincerely,</p>
                    <p style="margin-top: 70px;">
                        Mohammad Yousuf Aamir<br>
                        Managing Director<br>
                        Fintrek Marketing
                    </p>
                </div>
            </div>
        </body>
    </html>
    `

    if (format === "pdf") {

        const element = document.createElement('div');
        element.innerHTML = content;

        html2pdf()
            .from(element)
            .set({
                margin: 0,
                filename: `${employee.name}_Salary_Certificate.pdf`,
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
            })
            .save()
            .finally(() => {
                document.body.removeChild(element);
            });

    } else if (format === "word") {
        toast.error("This feature is not available right now!")

        // const converted = htmlDocx.asBlob(content);
        // saveAs(converted, `${employee.name}_Salary_Certificate.docx`);
    }
}