import React, { useEffect, useState } from "react"
import {
    Modal,
    Paper,
    Table,
    Typography,
    CircularProgress,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    Button,
    Box,
    Stack,
    Chip,
    Grid,
    styled,
    Stepper,
    Step,
    StepLabel,
} from "@mui/material"
import {
    cancelLeave,
    fetchLeaveHistory,
    fetchLeaveHistoryCount,
    fetchExistingLeaveBalance,
} from "../../../Actions/commonController"
import PaginationTable from "../../Pagination"
import { useAuth } from "../../../Context/authContext"
import { toast } from "react-toastify"
import { FormatDate } from "../../Utilities/DateFormatter"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"
import CircleIcon from "@mui/icons-material/Circle"
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty"


const LeaveBalanceCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
    borderRadius: '12px',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
}))

const DaysCircle = styled(Box)(({ color }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    width: "120px",
    height: "120px",
    backgroundColor: color + "33",
    color: color,
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "10px",
}))

const columns = [
    { id: "ID", label: "SR NO.", minWidth: 80 },
    { id: "requestDate", label: "REQUEST DATE", minWidth: 100 },
    { id: "type", label: "LEAVE TYPE", minWidth: 100 },
    { id: "startDate", label: "START DATE", minWidth: 100 },
    { id: "endDate", label: "END DATE", minWidth: 100 },
    { id: "duration", label: "DURATION", minWidth: 100 },
    { id: "reason", label: "REASON", minWidth: 100 },
    { id: "status", label: "STATUS", minWidth: 100 },
    { id: "actions", label: "ACTIONS", minWidth: 200 },
]

export default function PendingLeavesTable() {
    const [page, setPage] = useState(1)
    const [quantity, setQuantity] = useState(10)
    const [totalItems, setTotalItems] = useState(0)
    const [selectedReason, setSelectedReason] = useState("")
    const [reasonModalOpen, setReasonModalOpen] = useState(false)
    const [rejectModalOpen, setRejectModalOpen] = useState(false)
    const [cancelModalOpen, setCancelModalOpen] = useState(false)
    const [leaveData, setLeaveData] = useState([])
    const [leaveBalance, setLeaveBalance] = useState({})
    const [loader, setLoader] = useState(false)
    const [loading, setLoading] = useState(false)
    const [cancelLoader, setCancelLoader] = useState(false)
    const [leaveIdToCancel, setLeaveIdToCancel] = useState(null)
    const [dayLength, setDayLength] = useState("")
    const [statusFilter, setStatusFilter] = useState("all")
    const { eid } = useAuth()
    const [trackerModalOpen, setTrackerModalOpen] = useState(false)
    const [gender, setGender] = useState('')
    const [selectedRejectInfo, setSelectedRejectInfo] = useState({
        rejectedByName: "",
        rejectedreason: "",
    })
    const [trackerData, setTrackerData] = useState({
        pending: [],
        accepted: [],
        rejected: []
    })

    const leaveTypeNames = {
        annual: "Annual Leave(s)",
        sick: "Sick Leave(s)",
        maternity: "Maternity Leave(s)",
        paternity: "Paternity Leave(s)",
        emergency: "Emergency Leave(s)",
    }

    const fetchLeaveBalanceData = async () => {
        setLoading(true)
        try {
            let APIData = {
                empid: eid,
            }
            let { status, data } = await fetchExistingLeaveBalance(APIData)
            if (status === "success") {
                setLeaveBalance(data || {})
                setGender(data.gender)
            } else {
                console.error("Error fetching employee leave history", status)
            }
        } catch (error) {
            console.error("Error fetching employee leave history", error)
        }
        setLoading(false)
    }

    const loadData = async () => {
        setLoader(true)
        try {
            let APIData = {
                empid: eid,
                status: statusFilter,
                page: page,
                quantity: quantity,
            }
            let { status, data } = await fetchLeaveHistory(APIData)
            if (status === "success") {
                setLeaveData(data)
            } else {
                console.error("Error fetching employee leave history", status)
            }
        } catch (error) {
            console.error("Error fetching employee leave history", error)
        }
        setLoader(false)
    }

    const initialization = async () => {
        try {
            let APIData = {
                empid: eid,
                status: statusFilter
            }
            let { status, data } = await fetchLeaveHistoryCount(APIData)
            if (status === "success") {
                setTotalItems(data)
                loadData()
            } else {
                console.error("Error fetching leave history count", status)
            }
        } catch (error) {
            console.error("Error fetching leave history count", error)
        }
    }

    const cancelLeaveAction = async () => {
        try {
            let APIData = {
                leaveid: leaveIdToCancel,
                dayLength: dayLength,
            }
            setCancelLoader(true)
            let { status } = await cancelLeave(APIData)
            if (status === "success") {
                toast.success("Leave cancelled successfully!")
                fetchLeaveBalanceData()
                loadData()
            } else {
                toast.error("Error cancelling leave! (Contact administrator)")
            }
        } catch {
            toast.error("Error in server! (Contact administrator)")
        } finally {
            setCancelLoader(false)
            setCancelModalOpen(false)
            setLeaveIdToCancel(null)
        }
    }

    const handleFilter = async (status) => {
        setStatusFilter(status)
    }

    const handlePageClick = async (newPage) => {
        setPage(newPage)
    }

    const handleItemsPerPageChange = async (newRowsPerPage) => {
        setQuantity(newRowsPerPage)
        setPage(1)
    }

    const handleCancelClick = async (leaveid, dayLength) => {
        setLeaveIdToCancel(leaveid)
        setDayLength(dayLength)
        setCancelModalOpen(true)
    }

    const handleRejectInfo = (rejectedByName, rejectedreason) => {
        setSelectedRejectInfo({ rejectedByName, rejectedreason })
        setRejectModalOpen(true)
    }

    const handleCloseModal = () => {
        setReasonModalOpen(false)
        setRejectModalOpen(false)
        setCancelModalOpen(false)
        setTrackerModalOpen(false)
    }

    const handleTrackerButtonClick = (leave) => {
        const tracker = {
            pending: leave.pendingNames ? leave.pendingNames.split(",") : [],
            accepted: leave.acceptedNames ? leave.acceptedNames.split(",") : [],
            rejected: leave.rejectedNames ? leave.rejectedNames.split(",") : [],
        }
        setTrackerData(tracker)
        setTrackerModalOpen(true)
    }

    useEffect(() => {
        fetchLeaveBalanceData()
    }, [eid])

    useEffect(() => {
        initialization()
    }, [statusFilter, page, quantity, eid])

    const renderTracker = () => {
        const steps = [
            ...trackerData.accepted.map((name) => ({ name, status: "Approved" })),
            ...trackerData.pending.map((name) => ({ name, status: "Pending" })),
            ...trackerData.rejected.map((name) => ({ name, status: "Rejected" })),
        ]

        return (
            <Box sx={{ width: "100%" }}>
                <Stepper alternativeLabel>
                    {steps.map((step, index) => (
                        <Step key={index} active>
                            <StepLabel
                                StepIconComponent={() =>
                                    step.status === "approved" ? (
                                        <CheckCircleIcon color="success" />
                                    ) : step.status === "rejected" ? (
                                        <CancelIcon color="error" />
                                    ) : (
                                        <HourglassEmptyIcon color="warning" />
                                    )
                                }
                            >
                                {step.name}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
        )
    }

    const chipColor = (status) => {
        switch (status.toLowerCase()) {
            case "pending":
                return "primary"
            case "cancelled":
                return "warning"
            case "rejected":
                return "error"
            case "accepted":
                return "success"
            default:
                return "default"
        }
    }

    const LeaveBalanceBox = ({ leaveType, balance }) => {
        const leaveTypeColor = (leaveType) => {
            switch (leaveType.toLowerCase()) {
                case "annual": return "#1976d2";
                case "sick": return "#43a047";
                case "maternity": return "#ec407a";
                case "paternity": return "#ab47bc";
                case "emergency": return "#e53935";
                default: return "#757575";
            }
        }

        const renderLeaveDetails = () => {
            switch (leaveType.toLowerCase()) {
                case "sick":
                    return (
                        <>
                            <Typography variant="body2" sx={{ color: 'gray', paddingTop: "10px" }}>
                                Paid, Half Paid & Unpaid
                            </Typography>
                        </>
                    );
                case "maternity":
                    return (
                        <>
                            <Typography variant="body2" sx={{ color: 'gray', paddingTop: "10px" }}>
                                Paid, Half Paid
                            </Typography>
                        </>
                    );
                case "emergency":
                    return <Typography variant="body2" sx={{ color: 'gray', paddingTop: "10px" }}>Unpaid</Typography>;
                default:
                    return <Typography variant="body2" sx={{ color: 'gray', paddingTop: "10px" }}>Paid</Typography>;
            }
        }

        const color = leaveTypeColor(leaveType);

        return (
            <LeaveBalanceCard>
                <DaysCircle color={color}>
                    <div>{leaveType === 'emergency' ? balance.taken : leaveType === 'annual' ? balance : balance.total}d</div>
                    <Typography variant="body2" sx={{ fontSize: '14px', color: "gray" }}>
                        {leaveType === 'emergency' ? 'Taken' : 'Available'}
                    </Typography>
                </DaysCircle>
                <Typography variant="body1" sx={{ fontWeight: 'bold', paddingBottom: "10px" }}>
                    {leaveTypeNames[leaveType] || leaveType}
                </Typography>
                {renderLeaveDetails()}
            </LeaveBalanceCard>
        );
    };

    const getLeaveTypeColor = (leaveType) => {
        switch (leaveType.toLowerCase()) {
            case "annual":
                return "blue"
            case "sick":
                return "green"
            case "paternity":
                return "purple"
            case "maternity":
                return "pink"
            case "emergency":
                return "red"
            default:
                return "gray"
        }
    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    }

    return (
        <Box paddingTop={"30px"}>

            <Typography variant="h6" gutterBottom>
                Leave Balance
            </Typography>
            <Grid container spacing={2} justifyContent="center">
                {loading ? (
                    <CircularProgress size={60} sx={{ mt: 3 }} />
                ) : (
                    Object.keys(leaveBalance)
                        .filter((leaveType) => leaveType !== "gender")
                        .filter((leaveType) => leaveType !== "maternity" || gender === "Female")
                        .map((leaveType, index) => (
                            <Grid
                                item xs={12} sm={6} md={4} lg={2} xl={2.4}
                                key={index}>
                                <LeaveBalanceBox leaveType={leaveType} balance={leaveBalance[leaveType]} />
                            </Grid>
                        ))
                )}
            </Grid>

            <Paper className="pageComponent">
                <span className="info">
                    <h5>My Leaves</h5>
                </span>

                <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>

                    <Button
                        variant="contained"
                        onClick={() => handleFilter("all")}
                        sx={{
                            backgroundColor: statusFilter === "all" ? "#2596BE" : "transparent",
                            color: statusFilter === "all" ? "white" : "#2596BE",
                            marginRight: "10px",
                            "&:hover": {
                                backgroundColor: "#2596BE",
                                color: "white",
                            },
                        }}
                    >
                        All
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => handleFilter("Pending")}
                        sx={{
                            backgroundColor: statusFilter === "Pending" ? "#2596BE" : "transparent",
                            color: statusFilter === "Pending" ? "white" : "#2596BE",
                            marginRight: "10px",
                            "&:hover": {
                                backgroundColor: "#2596BE",
                                color: "white",
                            },
                        }}
                    >
                        Pending
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => handleFilter("Accepted")}
                        sx={{
                            backgroundColor: statusFilter === "Accepted" ? "#2596BE" : "transparent",
                            color: statusFilter === "Accepted" ? "white" : "#2596BE",
                            marginRight: "10px",
                            "&:hover": {
                                backgroundColor: "#2596BE",
                                color: "white",
                            },
                        }}
                    >
                        Accepted
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => handleFilter("Rejected")}
                        sx={{
                            backgroundColor: statusFilter === "Rejected" ? "#2596BE" : "transparent",
                            color: statusFilter === "Rejected" ? "white" : "#2596BE",
                            marginRight: "10px",
                            "&:hover": {
                                backgroundColor: "#2596BE",
                                color: "white",
                            },
                        }}
                    >
                        Rejected
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => handleFilter("Cancelled")}
                        sx={{
                            backgroundColor: statusFilter === "Cancelled" ? "#2596BE" : "transparent",
                            color: statusFilter === "Cancelled" ? "white" : "#2596BE",
                            "&:hover": {
                                backgroundColor: "#2596BE",
                                color: "white",
                            },
                        }}
                    >
                        Cancelled
                    </Button>
                </div>

                <div className="row" style={{
                    paddingTop: "60px",
                }}>
                    <div className="col-md-12">
                        <TableContainer
                            sx={{
                                overflowY: "auto",
                                maxHeight: "500px"
                            }}
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                className="tableHeader"
                                                key={column.id}
                                                align="center"
                                                style={{
                                                    minWidth: column.minWidth,
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {loader ? (
                                        <TableRow>
                                            <TableCell colSpan={11} style={{ padding: "30px 0", textAlign: "center" }}>
                                                <CircularProgress size={50} />
                                            </TableCell>
                                        </TableRow>
                                    ) : leaveData && leaveData.length > 0 ? (
                                        leaveData.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="center" className="p-0"> {item.id}</TableCell>
                                                <TableCell align="center" className="p-0">{FormatDate(item.createdAt)}</TableCell>
                                                <TableCell align="center" className="p-0">
                                                    <Box display="flex" justifyContent="flex-start" sx={{ paddingLeft: 2, width: "100%" }}>
                                                        <Stack direction="row" alignItems="center" spacing={1}>
                                                            <CircleIcon sx={{ color: getLeaveTypeColor(item.type), fontSize: 14 }} />
                                                            <Typography variant="body2" align="left">
                                                                {item.type}
                                                            </Typography>
                                                        </Stack>
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="center" className="p-0">{FormatDate(item.startdate)}</TableCell>
                                                <TableCell align="center" className="p-0">{FormatDate(item.enddate)}</TableCell>
                                                <TableCell align="center" className="p-0">{item.duration}</TableCell>
                                                <TableCell align="center" className="p-0">
                                                    <Button variant="contained" className="tableButton" onClick={() => {
                                                        setSelectedReason(item.reason)
                                                        setReasonModalOpen(true)
                                                    }}
                                                    >
                                                        View
                                                    </Button>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Stack direction="row" spacing={1}>
                                                        <Chip label={capitalizeFirstLetter(item.status)} color={chipColor(item.status)} />
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="center">
                                                    {item.status === "pending" ? (
                                                        <>
                                                            <Button variant="contained" className="btn rejected" onClick={() => handleCancelClick(item.id, item.reason)}>
                                                                Cancel
                                                            </Button>
                                                            <Button variant="contained" className="btn tracker" onClick={() => handleTrackerButtonClick(item)}>
                                                                Track
                                                            </Button>
                                                        </>
                                                    ) : item.status === "accepted" ? (
                                                        "-"
                                                    ) : item.status === "rejected" ? (
                                                        <Button variant="contained" className="btn rjct" onClick={() => handleRejectInfo(item.rejectedByName, item.rejectedreason)}>
                                                            Info
                                                        </Button>
                                                    ) : item.status === "cancelled" ? (
                                                        "-"
                                                    ) : (
                                                        "-"
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={11} style={{ padding: "30px 0", textAlign: "center" }}>
                                                No data found
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>

                            </Table>
                        </TableContainer>

                        <PaginationTable
                            totalItems={totalItems}
                            itemsPerPage={quantity}
                            onPageChange={handlePageClick}
                            onItemsPerPageChange={handleItemsPerPageChange}
                        />
                    </div>
                </div>
            </Paper>

            {/* Reason Modal */}
            <Modal
                open={reasonModalOpen}
                onClose={() => setReasonModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper className="floatingPaper">
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Reason
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {selectedReason}
                    </Typography>
                </Paper>
            </Modal>

            {/* Rejection Info Modal */}
            <Modal
                open={rejectModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "background.paper",
                        border: "none",
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography id="modal-title" variant="h6" component="h2">
                        Reason for Rejection
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        {selectedRejectInfo.rejectedreason}
                    </Typography>
                    <Typography sx={{ mt: 2, fontStyle: "italic" }}>
                        Rejected by: {selectedRejectInfo.rejectedByName}
                    </Typography>
                </Box>
            </Modal>

            {/* Cancel Confirmation Modal */}
            <Modal
                open={cancelModalOpen}
                onClose={() => setCancelModalOpen(false)}
                aria-labelledby="cancel-modal-title"
                aria-describedby="cancel-modal-description"
            >
                <Paper className="floatingPaper">
                    <Typography id="cancel-modal-title" variant="h6" component="h2">
                        Cancel Leave
                    </Typography>
                    <Typography id="cancel-modal-description" sx={{ mt: 2 }}>
                        Are you sure you want to cancel this leave?
                    </Typography>
                    <div
                        style={{
                            display: "flex",
                            marginTop: "20px",
                            width: "370px",
                            justifyContent: "space-between",
                        }}
                    >
                        <Button
                            variant="contained"
                            onClick={() => setCancelModalOpen(false)}
                            style={{
                                backgroundColor: "#FF0000",
                                color: "white",
                                width: "60px",
                                height: "35px",
                            }}
                        >
                            No
                        </Button>
                        <Button
                            variant="contained"
                            onClick={cancelLeaveAction}
                            style={{
                                backgroundColor: "#1976d2",
                                color: "white",
                                width: "60px",
                                height: "35px",
                            }}
                            disable={cancelLoader}
                        >
                            {cancelLoader && (
                                <CircularProgress
                                    size={24}
                                    color="inherit"
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: -12,
                                        marginLeft: -12,
                                    }}
                                />
                            )}
                            {!cancelLoader && "Yes"}
                        </Button>
                    </div>
                </Paper>
            </Modal>

            {/* Tracker Modal */}
            <Modal
                open={trackerModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="tracker-modal-title"
                aria-describedby="tracker-modal-description"
            >
                <Paper className="floatingPaper">
                    <Typography id="tracker-modal-title" variant="h6" component="h2">
                        Approval Tracker
                    </Typography>
                    <Typography id="tracker-modal-description" sx={{ mt: 2 }}>
                        {renderTracker()}
                    </Typography>
                </Paper>
            </Modal>

        </Box>
    )
}