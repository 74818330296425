import { React, useEffect, useState } from "react"
import DashboardAdmin from "../../Components/dashboardAdmin"
import { useAuth } from "../../Context/authContext"
import DashboardEmployees from "../../Components/dashboardEmployees"
import { welcome } from "../../Actions/commonController"
import { CircularProgress } from "@mui/material"

const getGreetingMessage = () => {
  const currentHour = new Date().getHours()
  if (currentHour < 12) {
    return "Good Morning"
  } else if (currentHour < 18) {
    return "Good Afternoon"
  } else {
    return "Good Evening"
  }
}

const formatName = (fullName) => {
  const nameParts = fullName.split(' ')
  return nameParts.slice(0, 2).join(' ')
}

function Dashboard() {
  const { tier, eid } = useAuth()
  const [currentDateTime, setCurrentDateTime] = useState(new Date())
  const [userData, setUserData] = useState({ name: "" })
  const [isLoading, setIsLoading] = useState(true)

  const fetchUserData = async () => {
    try {
      let APIData = {
        empid: eid,
      }
      const { status, data } = await welcome(APIData)
      if (status === "success") {
        setUserData(data)
        setIsLoading(false)
      } else {
        console.error("Failed to fetch user data:", status)
        setIsLoading(false)
      }
    } catch (error) {
      console.error("Error fetching user data:", error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchUserData()
  }, [])

  return (
    <div>
      {/* Header with Greeting and Date/Time */}
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div>
          {isLoading ? (
            <h1><CircularProgress size={24} /></h1>
          ) : (
            <h1>{`${getGreetingMessage()}, ${formatName(userData.name)}!`}</h1>
          )}
        </div>
      </div>

      {/* The rest of your dashboard */}
      {tier === 4 ? <DashboardEmployees /> : <DashboardAdmin />}
    </div>
  )
}

export default Dashboard